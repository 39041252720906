/*****modals*****/
#alert-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FFFAA0;
}

#ask-to-leave-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FAA0A0;
}

#must-leave-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FAA0A0;
}

#max-alerts-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FAA0A0;
}

#already-asked-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FAA0A0;
}

#self-leave-window .MuiBackdrop-root {
    opacity: .5 !important;
    background-color: #FAA0A0;
}
#green-modal .MuiBackdrop-root{
    opacity:.5!important;
    background-color: #c6edc6;
}
/**maxalert+alreadyasked******/
.red-border-card-modal {
    font-size: 1rem!important;
    padding: 1rem;
    width: 22rem;
    border: 3.5px solid #FA8072;
    border-radius: 15px !important;
    position: absolute;
    top: 35%;
    right: 55%;
    font-weight: 500!important;
    color: rgba(0, 0, 0, 0.42)!important;;
}

/***askToLeave+alert****/
#modal-text {
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.modal-icon {
    margin-right: 6px;
}
.modal-icon-2{
    margin:0 1rem;
}

.border-card-modal {
    text-align: center;
    border: 3.5px solid;
    border-radius: 15px !important;
    position: absolute;
    /* right: 55%;
    top: 15%; */
    right:45%;
    top:30%;
}

/****self+mustleave****/

.red-card-modal {
    position: absolute;
    width: 22rem;
    padding: 1rem;
    top: 40%;
    right: 40%;
    border-radius: 15px !important;
}

#leave-button-modal {
    margin-top: 7px;
    background-color: #ff5e47;
    color: white;
    border-radius: 5px;
    font-size: large;
    text-transform: none;
    width: 100%;
}

@media only screen and (min-width:350px) and (max-width:650px ){
    .border-card-modal {
        top:32%;
        right:19%;
        width:15rem;
    }
    .red-border-card-modal{
        right:25%;
    }
    .red-card-modal{
        right: 32%;
        width: 15rem;
    }
}