.dropdown-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  min-width: 120px;
}

.pointer {
  cursor: pointer;
}

.dropdown-container * {
  width: 100%;
  box-sizing: border-box;
}

.dropdown-value {
  padding: 10px;
  font-weight: 400;
}

.dropdown-content-border {
  border-bottom: solid 1px #00000030;
}

.dropdown-content {
  padding: 10px;
  color: #7f8e9d;
  font-weight: 400;
  font-size: 1rem !important;
}

.dropdown-content:hover {
  background: #ccc;
}

.border-grey {
  border: 1px solid #00000030;
}

.dropdown-border {
  border: solid 1px #E0E0E0;
  outline: none;
  border-radius: 10px;
  overflow: hidden;
}

.dropdown-border .dropdown-icon {
  color: #27272a;
}

.dropdown-border-active {
  border: solid 1px #ffcb00;
  background-color: rgba(255, 203, 0, 0.1);
}

.dropdown-border-active .dropdown-icon {
  color: #ffcb00;
}

.dropdown-content-container {
  cursor: auto;
  color: #27272a;
  background-color: #fff;
  display: none;
  position: absolute;
  min-width: 120px;
  width: 100%;
  z-index: 1;
  border: solid 1px #00000030;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  left: 0px;
}

.dropdown-content-scroll {
  max-height: 13rem;
  overflow: auto;
  text-align: start;
}

.block {
  display: block !important;
}

.justify_sb {
  display: flex;
  justify-content: space-between;
}

.flex_sb_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_sb_center > input {
  height: 50px;
  border: none;
  outline: none;
  font-size: 18px;
}

/* / / Chrome, Safari, Edge, Opera / / */
.flex_sb_center > input::-webkit-outer-spin-button,
.flex_sb_center > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / / Firefox / / */
.flex_sb_center > input[type="number"] {
  -moz-appearance: textfield;
}

.flex_sb_center > input:focus {
  color: black;
}

.country-code-div {
  border-right: solid #00000030 1px;
  border-radius: 0px 10px 10px 0px;
  max-width: max-content;
  color: #888;
}

.selected {
  background: rgba(0, 0, 0, 0.08);
  color: #334155;
}

#Demoo {
  border-radius: 0px 0px 10px 10px;
  font-size: 20px;
}

.bottom-flat-border {
  border-radius: 10px 10px 0px 0px;
}

.phone-input {
  padding: 0 10px;
}

/* / ///////////////////////// / */

/* / / width / / */
::-webkit-scrollbar {
  width: 8px;
}

/* / / Handle / / */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* / / Handle on hover / / */
::-webkit-scrollbar-thumb:hover {
  background: #00000090;
}

.drop_down_flag_style {
  font-size: 30px;
}

.drop_down_countryName_style {
  font-size: 20px;
  padding: 0px 15px;
}

.refund-options {
  gap: 0.5rem;
}

.refund-options > div {
  flex: 1;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e5e5;
  border-radius: 8rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.refund-options .active {
  border: solid 1px #ffcb00;
  background-color: rgba(255, 203, 0, 0.1);
}

.refund-options svg {
  margin-right: 1rem;
}

.refund-options p {
  color: #7f8e9d;
}
