.coinLabel {
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    flex-basis: 48% !important;
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-bottom: 3px solid #eaeaea;
}

.coinPriceOutsideBox {
    border: 3px solid #eaeaea;
    border-radius: 10px;
    padding: 10px;
    min-height: 300px;
    justify-content: space-around;
}

.coinBody {
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    padding: 0px;
}

.coinDataSpan {
    font-size: 18px !important;
    color: #aeaeae;
    font-weight: 600;
    /* padding: 5px; */
    width: 100%;
}

.boldText {
    font-size: 16px;
    font-weight: 700;
    color: #232323;
    margin-bottom: 5px;
}

.coinRefillBox {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
}

.coinRefillBox.active {
    display: block;
    position: fixed;
    z-index: 1200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
}

.coinRefillBoxContent {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    transition: 0.5s;
    border: 1px solid #888;
    border-radius: 12px;
    width: 60% !important;
    max-width: 1000px !important;
    padding: 40px 120px;
    position: relative;
    /* height: 500px; */
}

.coinRefillBoxContent svg {
    position: absolute;
    top: 20px;
    right: 20px;
}


.paymentMethod {
    border-radius: 8px;
    border: 3px solid #eaeaea;
    margin-top: 0px;

}

.paymentMethodName {
    font-size: 16px;
    font-weight: 500;
    padding: 20px 40px;
    border-bottom: #eaeaea 3px solid;
}

.paymentMethod:last-child {
    border-bottom: none !important;
}

.paymentMethod span {
    padding-left: 20px;
}

.payNowButton {
    cursor: pointer;
    margin-top: 5px;
    padding: 16px;
    background-color: #232344;
    color: #fff;
    font-size: 20px;
    border-radius: 12px;
    text-align: center;
    font-weight: 600;
}

.payNowButtonDisabled {
    cursor: pointer;
    margin-top: 5px;
    padding: 16px;
    background-color: #aeaeae;
    color: #fff;
    font-size: 20px;
    border-radius: 12px;
    text-align: center;
    font-weight: 600;
}

.coinEarnBox {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.coinRefillBoxSuccess {
    margin: 5% auto;
    border: 1px solid #888;
    border-radius: 19px;
    width: 36% !important;
    max-width: 750px !important;
    background: linear-gradient(to bottom, #ffeb3b 50%, #fefad8 80%);
}
.coinRefillBoxImg {
    position: relative;
}

.coinRefillBoxImg .ylanesLogo {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
}

.coinRefillBox img {
    object-fit: contain;
    width: 100%;
}

.coinRefillBox .coinRefillBoxSuccessText {
    margin: 20px 50px;
    margin-top: 50px;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    padding: 30px;
    border-radius: 18px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.coinRefillBoxSuccessText span {
    padding: 20px 0px;
    display: block;
    width: 100%;
}

.coinRefillBoxSuccessTextBold {
    width: 100%;
    font-weight: 700;
    font-size: 21px;
}

.coinRefillBoxSuccessReferFriend {
    width: 100%;
    margin: 20px 0px;
    font-size: 22px;
    text-align: center;
    color: #232344
}

.coinRefillBoxSuccessContinue {
    width: 100%;
    margin: 50px 50px;
    margin-top: 0px;
    padding: 16px;
    background-color: #232344;
    cursor: pointer;
    color: #fff;
    font-size: 22px;
    border-radius: 12px;
    text-align: center;
    font-weight: 600;
}

@media only screen and (max-width: 900px) {

    .coinRefillBoxContent {
        background-color: #fefefe;
        margin: 5% auto;
        padding: 20px;
        border: 1px solid #888;
        border-radius: 12px;
        width: 95% !important;
        max-width: 1000px !important;
        padding: 40px 20px;
        /* height: 500px; */
    }

    .coinRefillBoxSuccess {
        overflow: auto;
        width: 100% !important;
        margin: 0px;
    }

    .coinRefillBox .coinRefillBoxSuccessText {
        margin: 10px;
    }

    .coinRefillBoxSuccessContinue {
        margin: 20px 10px;
    }

    .coinEarnBox {
        font-size: 16px;
        font-weight: 600;
    }
}