.mobileAccordion {
   .myAccordion {
      padding: 0px !important;
      border: none !important;
      margin-top: 0px;
      border-top: 1px #e5e5e5 solid !important;
      border-radius: 0px !important;
   
   }

   .MuiAccordionSummary-content.Mui-expanded {
      margin: 0px;
   }
   
   .myAccordion .MuiIconButton-label svg {
      fill: #222222;
   }
   
   .myAccordion .MuiIconButton-edgeEnd {
      margin: 0px;
   }
   
   .myAccordion::-webkit-scrollbar {
      width: 8px;
      box-shadow: none;
   }
   
   .myAccordion::-webkit-scrollbar-track {
      width: 8px;
      box-shadow: none;
   }
   
   .myAccordionInside {
      padding: 0px !important;
      display: block !important;
   }
   
   .accordionHeader {
      padding: 0px !important;
   }
   
   #panel1d-header {
      padding: 0px !important;
   }
   
   .accordionModel {
      color: #8e8e8e;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
   }
   
   .accordionExplain {
      margin-top: 6px;
      color: #222255;
      font-weight: 700;
      font-size: 20px;
   }
   
   .accordionDesc {
      margin-top: 10px;
      color: #8e8e8e;
      font-size: 18px;
   }
   
   /* RATING */
   
   .ratingBox {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
   }
   
   .earnedCoinsCurrentRating {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 600;
   }
   
   .earnedAndTime {
      display: flex;
      flex-direction: column;
   }
   
   .moderatorExplainText {
      font-size: 14px;
      width: 100%;
      font-weight: 500;
   }
   
   .moderatorExplainText ul {
      padding: 0px 20px;
   }
   
   .moderatorExplainText span {
      color: #222255;
   }
   
   .timeAndHearth {
      margin-top: 3px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      color: rgba(1, 0, 57, 0.4);
   }
   
   .ratingDesc {
      padding:12px;
      border-radius: 8px;
      border: 1px solid rgba(1, 0, 57, 0.4);
      margin-top: 30px;
      color: rgba(1, 0, 57, 0.4);
      font-weight: 600;
      font-size: 14px;
      display: flex;
   }
   
   .ratingDesc img {
      margin-top: 5px;
      margin-right: 12px;
      width: 20px;
      height: 20px;
   }
   
   .accounStatementDataBox {
      border-bottom: 1px solid #e5e5e5;
      margin-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      font-weight: 600;
   }
   
   .accountDataTypeAndPrice {
      display: flex;
      justify-content: space-between;
      color: #222255;
      font-size: 14px;
      font-weight: 600;
   
   }
   
   .accountDataTıme {
      margin-top: 5px;
      color: #8e8e8e;
      font-size: 14px;
   }
   
   .accountParticipants {
      margin-top: 5px;
      font-size: 14px;
      color: #8e8e8e;
   }
   
   .accountCompleted {
      margin-top: 5px;
      font-size: 14px;
      gap: 10px;
      display: flex;
      color: #8e8e8e;
   }
   
   .heartCount {
      display: flex;
      align-items: center;
   }
   
   .hearthCountBad {
      display: flex;
      align-items: center;
   }
   
   .heartCount img {
      margin-right: 4px;
      width: 14px;
      height: 14px;
   }
   
   .hearthCountBad img{
      margin-right: 4px;
      width: 14px;
      height: 14px;
      filter: grayscale(100%)
   }
   
}