.myWallet {
   height: calc(100vh - 65px);
   margin-top: 65px !important;
   overflow: hidden;
   justify-content: center;
   border-top: 1px solid #E0E0E0;

   .myWalletInside {
      height: 100%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #E0E0E0;
      overflow: auto;
   }

   .myWalletInsideRight {
      height: 100%;
      background-color:#FBFBFB;
      padding: 0px 30px;
      // padding-right: 5%;
      overflow-y: auto;
      overflow-x: hidden;
      border: none;
      color: #232323
   }

   .myWalletModerator {
      margin-top: 20px;
      overflow-y: scroll;
      padding-right: 10px;
      transition: .5s;
      // height: calc(100vh - 420px);
      margin-right: -28px;
   }

   .myWalletModerator::-webkit-scrollbar-track-piece {
      background-color: white;
   }

   .myWalletModerator::-webkit-scrollbar-track {
      background: #fff;
      box-shadow: none;
   }

   .myWalletModerator .MuiSlider-track {
      background: white;
   }


   .walletBalanceMain {
      margin-left: auto;
      // width: 100%;
      margin-top: 30px;
      height: 110px;
      border-radius: 12px;
      border: 1px solid;
      background-color: #FFFAE5;
      border-color: #FFCB00;
      font-weight: 600;
      padding: 24px;
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: space-around;
      overflow: hidden;
      img {
         position: absolute;
         top: 0%;
         right: 0%;
      }
      .walletIcon {
         position: absolute;
         top: 8px;
         right: 8px;
      }
      &.moderator {
         border-color: #010039;
         background:rgba(1, 0, 57, 0.1);
         .balance {
            font-size: 16px;
            height: 22px;
            display: flex;
            align-items: flex-start;
            color: #7F8E9D;
         }

         .coinAmount {
            display: flex;
            align-items: flex-end;
            height: 43px;
            font-size: 32px;
            color: #222255;
         }

         .earnedCoin {
            font-size: 18px;
            height: 30px;
            padding: 0px 10%;
            color: #7F8E9D;
         }
         // background-color:#010039 ;
      }
   }

   .balance {
      font-size: 16px;
      height: 22px;
      display: flex;
      align-items: flex-start;
      color: #7F8E9D;
   }

   .coinAmount {
      display: flex;
      align-items: flex-end;
      min-height: 43px;
      font-size: 32px;
      color: #222255;
   }

   .earnedCoin {
      font-size: 18px;
      height: 30px;
      padding: 0px 10%;
      color: #7F8E9D;
   }

   .refillWalletButton {
      margin-top: 20px;
      cursor: pointer;
      padding: 10px;
      background-color: #FFCB00;
      color: #010039;
      font-size: 16px;
      border-radius: 8px;
      text-align: center;
      font-weight: 500;
      &.admin {
         background-color: #010039;
         color:#fff;
      }
   }

   .refillWalletButton-disabled {
      margin-top: 20px;
      pointer-events: none;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
      font-size: 16px;
      border-radius: 8px;
      text-align: center;
      font-weight: 500;
      &.admin {
         background-color: #010039;
         color:#fff;
      }
   }

   .p40Flex{
   //    display: flex;
   //  flex-wrap: nowrap;
    height: 100%;
      position: relative;
      padding: 0px 40px;
   }

   .walletTopWrapper {
      border-bottom: 1px solid #E0E0E0;
      padding: 20px 40px;
      .walletBackButton {
         cursor: pointer;
         font-size: 24px;
         display: flex;
         font-weight: 500;
         cursor: pointer;
         padding-bottom: 15px;
         align-items: center;
         padding-left: 0px;
         text-align: left;
      }
   
      .walletBackButton img {
         margin-right: 13px;
      }
   }


   .transactionLabel {
      width: 100%;
      font-size: 22px;
      color: #222255;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 10px;
      display: block;
   }
   
   .transactionSecondLabelBox {
      display:flex;
      justify-content: space-between;
   }

   .yCoinLabel {
      font-size: 18px;
      color: #8e8e8e;
      display: block;
      font-weight: 600;
   }

   .transaction {
      width: 100%;
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;
   }

   .transactionBox {
      font-size: 16px;
      font-weight: 500;
      padding: 20px 0px;
      padding-bottom: 10px;
      border-bottom: 1px solid #E0E0E0;
      width: 100%;
      display: flex;
      transition:.3s ;
      &:hover {
         background-color: #fff;
      }
   }

   .transactionLeftBox {
      width: 60px;
   }

   // .transactionLeftBox img {
   //    width: 32px;
   //    height: 32px;

   // }

   .transactionMiddleBox {
      width: 100%;
   }

   .transactionRight {
      color: #222255;
      width: 100px;
      text-align: right;
   }

   .transactionType {
      display: block;
      width: 100%;
      color: #222255;
   }

   .transactionDate {
      display: block;
      width: 100%;
      color: #c7cad9;
   }

   .feedbackHighlight {
      color: #222255;
      cursor:pointer;
      text-decoration: underline;
   }

   .feedbackOutside {
      margin-bottom:20px;
      margin-top: 20px;
      display: flex;
      height: 100%;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
   }

   .feedbackDiv {
      width: 60%;
      padding: 10px 10px;
      font-size: 17px;
      text-align: center;
      color: #8e8e8e;
      font-weight: 500;
   }
}

.sliderBaseWallet {
   width: 100%;
   height: 50px;
   padding: 5px;
   border-radius: 25px;
   background-color: #f9f9f9;
   display: flex;
   font-size: 16px;
   font-weight: 600;
   .insideButtons {
      width: 50%;
      height: 100%;
      color: #a2a2a2;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .5s;
      &.active {
         box-shadow: 0px 1px 2px 0px #0000001A;
         color:#1e3354;
         cursor: default;
         background-color: #fff;
      }
   }
}

@media only screen and (max-width: 900px) {
   .accountDetailsOutside {
     .accountDetailsFrom {
      width: 100%;
     } 
   }
   .myWallet {
      &.mobileMyWallet {
         height: calc(100vh - 65px);
         margin-top: 65px !important;
         overflow: hidden;
         justify-content: center;
         .topBar {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            border-bottom: 1px solid #E0E0E0;
            .walletBackButton {
               padding-bottom: 0px;
            }
            img {
               position: absolute;
               left: 20px;
               bottom: 18px;
            }
         }
         .wrapperInside {
            padding:20px;
            overflow: auto;
            height: calc(100vh - 110px );
            .walletBalanceMain {
               padding: 6px 20px;
               height: auto;
               margin-top: 0px;
            }
            .moderatorExplainText {
               font-size: 10px;
            }
            .ratingDesc {
               margin-top: 15px;
            }
            .earnedCoinsCurrentRating {
               font-size: 12px;
            }
            .timeAndHearth {
               font-size: 10px;
            }
            .transactionLeftBox {
               display: flex;
               align-items: center;
               img {
                  width: 30px !important;
                  height: 30px !important;
               }
            }
            .transactionLabel {
               display: none;
            }
            .transactionSecondLabelBox {
               font-size: 16px;
            }
            .transactionType {
               font-size: 14px;
            }
            .transactionDate {
               font-size: 12px;
            }
            .refillWalletButton {
               border-radius: 24px;
            }
            .enterBankDetailsButton {
               border-radius: 24px;
               margin-top: 20px;
               margin-bottom: 20px;
            }
            .bankDetailsOutsideBox {
               padding: 0px;
               width: 100%;
               .bankDetailsBox {
                  margin-top: 0px;
               }
            }
            .d-flex {
               flex-direction: column-reverse;
            }
            .accountStatementLabels {
               display: none;
            }
            .walletLinks {
               justify-content: space-between;
               display: flex;
               padding-top:20px;
               align-items: center;
               .text {
                  width: 100%;
                  font-weight: 500;
                  font-size: 16px;
                  color: #010039;
                  padding:5px 10px;
                  span {
                     display: block;
                     color: #c7cad9;
                     font-size: 12px;
                  }
               }
               img {
                  width: 30px;
                  height: 30px;
               }
            }
         }
      }
   } 
    //--------------------- FOR MOBILE

 .fade-enter {
   transform: translateX(-150%);
   position: absolute;
   width: 100%;
   opacity: 0;
   transition: opacity 200ms ease-in, transform 500ms ease-in;
 }
 
 .fade-enter-active {
   transform: translateX(0);
   opacity: 1;
 }
 
 .fade-exit {
   transform: translateX(0);
   opacity: 1;
   transition: opacity 200ms ease-out, transform 500ms ease-out;
 }
 
 .fade-exit-active {
   transform: translateX(-150%);
   width: 100%;
   position: absolute;
   opacity: 0;
 }

 .fade-right-enter {
   transform: translateX(150%);
   position: absolute;
   width: 100%;
   opacity: 0;
   transition: opacity 200ms ease-in, transform 500ms ease-in;
 }
 
 .fade-right-enter-active {
   transform: translateX(0);
   opacity: 1;
 }
 
 .fade-right-exit {
   transform: translateX(0);
   opacity: 1;
   transition: opacity 200ms ease-out, transform 500ms ease-out;
 }
 
 .fade-right-exit-active {
   transform: translateX(150%);
   width: 100%;
   position: absolute;
   opacity: 0;
 } 
}

@media only screen and (max-width: 760px) {
   .myWallet {
      &.mobileMyWallet {
         overflow-y: auto;
      }
   }
}

.fade-enter {
   transform: translateX(-150%);
   position: absolute;
   width: 100%;
   opacity: 0;
   transition: opacity 400ms ease-in, transform 1000ms ease-in;
 }
 
 .fade-enter-active {
   transform: translateX(0);
   opacity: 1;
 }
 
 .fade-exit {
   transform: translateX(0);
   opacity: 1;
   transition: opacity 400ms ease-out, transform 1000ms ease-out;
 }
 
 .fade-exit-active {
   transform: translateX(-150%);
   width: 100%;
   position: absolute;
   opacity: 0;
 }

 // FADE RIGHT

 .fade-right-enter {
   transform: translateX(150%);
   position: absolute;
   width: 100%;
   opacity: 0;
   transition: opacity 400ms ease-in, transform 1000ms ease-in;
 }
 
 .fade-right-enter-active {
   transform: translateX(0);
   opacity: 1;
 }
 
 .fade-right-exit {
   transform: translateX(0);
   opacity: 1;
   transition: opacity 400ms ease-out, transform 1000ms ease-out;
 }
 
 .fade-right-exit-active {
   transform: translateX(150%);
   width: 100%;
   position: absolute;
   opacity: 0;
 }

 
