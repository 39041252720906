.mainContainerUserProfile {
  display: flex;
  background-color: #F1F5F9;
  padding-top: 50px;
  height: 100%;

  .scrollbarY {
    &.left {
      padding: 0px;
    }

    &.right {
      height: calc(100vh - 110px);
      padding: 0px;
      overflow-y: auto;
      overflow-x: hidden
    }
  }

  .mainMiddle {
    padding: 0px 20px;
  }
}

.column1-main-box {
  text-align: center;
  padding: 1px 10px;
}

.column1-child-box1 {
  margin: auto;
  border: 1px solid #dbd8d8;
  border-radius: 12px;
  background-color: #ffffff;
}

.tick-image {
  background-color: #010039;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0px;
  bottom: 5px;
  z-index: -1;
}

.connectionbox {
  width: 90%;
  border: 1px solid #dbd8d8;
  border-radius: 12px;
  background-color: #ffffff;
}

@media (max-width: 850px) {
  .column1-child-box1 {
    width: 100%;
    margin: auto;
    border: 1px solid #dbd8d8;
    border-radius: 12px;
    background-color: #ffffff;
  }
}

@media (max-width: 450px) {
  .column1-child-box1 {
    width: 100%;
    margin: auto;
    border: 1px solid #dbd8d8;
    border-radius: 12px;
    background-color: #ffffff;
  }
}

.penIcon {
  width: 16px;
  height: 16px;
  /* padding-left: -10px; */
  margin-right: 18px;
  cursor: pointer;
}

.penIcon2 {
  width: 16px;
  height: 16px;
  /* padding-left: 10px; */
  margin-right: 12px;
  cursor: pointer;
}

#profilePic {
  width: 70px;
  height: 70px;
}

#profilePic0 {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  margin-top: 5px;
  border-radius: 50%;
  object-fit: cover;
}

.profileChildIcon {
  width: 18px;
}

.hearts {
  margin-top: 10px;
  color: #c4c6cb;
}

.hearts1 {
  margin-top: 15px;
  color: #c4c6cb;
}

.hearts2 {
  margin-top: 10px;
  color: #010039;
  font-weight: 700;
}

.column1-child-box1-clild1 {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
}

#profile-name {
  text-align: left;
  font-weight: 500;
  font-size: 22px;
  color: #010039;
  white-space: nowrap;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slider0 {
  margin-top: -10px;
}

.slider0 .MuiSlider-track {
  height: 4px;
  display: block;
  position: absolute;
  border-radius: 3px;
  background-color: #fbca0c;
  color: #d9d9d9;
}

.slider0 .MuiSlider-rail {
  width: 100%;
  height: 4px;
  display: block;
  opacity: 0.38;
  position: absolute;
  border-radius: 4px;
  background-color: gray;
}

.slider0 .MuiSlider-thumb {
  position: initial !important;
}

.subcategories-box {
  justify-content: space-between;
  width: 100%;
}

#UserPersonalProfile-connected-btn {
  background-color: #010138;
  color: ffffff;
  height: 35px;
  border-radius: 16px;
  width: 100px;
  border: none;
}

.touch-points {
  color: #010138;
  font-weight: 500;
  cursor: pointer;
}

/* //middle box css */

.middle-box {
  border-radius: 15px;
  border: 1px solid #dbd8d8;
  background-color: white;
}

.hidden {
  overflow: hidden;
  word-break: break-all;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.documentsBox {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.documentLinks {
  width: fit-content;
  display: flex;
  max-width: 200px;
  transition: .5s;
}

.documentLinks a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 2px solid #fbca0c;
  border-radius: 8px;
  padding: 5px 10px;
  background-color: #fbca0c;
  transition: .5s;
  font-weight: 600;
  color: #010138;
}

.documentLinks:hover a {
  cursor: pointer;
  color: #010138;
  background-color: #fff;
}

.mainTopStatistics {
  text-align: center;
  display:flex;
  margin-bottom: 10px;
  flex-direction: column;
  // width: 50%;
  .label {
    font-size: 15px;
    color:rgb(189, 189, 191);
    font-weight: 500;
  }
  .count {
    font-weight: 600;
  }
}

#middel-box-lable {
  word-break: break-word;
  font-size: 16px;
  font-weight: 500;
  color: #010039;
}

#middel-box-text {
  font-size: 16px;
  font-weight: 600;
  color: #787878;
}

/* THird column */

.right-side-btn {
  text-transform: none;
  margin-top: 30px;
  width: 90%;
  border: 1px solid #dbd8d8 !important;
  /* margin-left: 10px !important; */
}

.right-side-btn-div .MuiButton-root {
  border-radius: 12px !important;
  padding: 16px 16px 16px 10px !important;
  background: #fff !important;
}

.column1-main-box {
  padding: 1px 30px;
  padding-left: 0px;
}

@media (max-width: 850) {
  .column1-main-box {
    padding: 1px 10px;
  }
}

@media (max-width: 450) {
  .column1-main-box {
    padding: 1px 5px;
  }
}

.column2-child-box2 {
  width: 100%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbarY {
  height: 90vh;
  overflow-y: auto;
}

.messageIconButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 35px;
}

.touch-points-profile {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.touch-points-profil img {
  width: 25%;
}

.touch-points-profile .touch-points-profile-name {
  text-align: left;
  min-width: 30%;
  flex: 1;
}

.touch-points-profile .touch-points-profile-name p:first-child {
  color: #010039;
  font-weight: 500;
  cursor: pointer;
}

.touch-points-profile .touch-points-profile-name p:last-child {
  color: #8e8e8e;
  font-size: 12px;
}

.connectedBtn, .touch-points-profile .touch-points-profile-action button {
  /* cursor: pointer; */
  border: none;
  transition: all 0.3s ease-in-out;
  background-color: #010039;
  border-radius: 20px;
  padding: 5px 10px;
  color: white;
}


/* .touch-points-profile .touch-points-profile-action button:hover {
  scale: 1.05;
}

.touch-points-profile .touch-points-profile-action button:active {
  background: #010014;
  scale: 0.9;
} */

.touch-points-profile .touch-points-profile-action button:disabled {
  pointer-events: none;
  background-color: #787878 !important;
  color: #fff !important;
}

.touch-points-profile .touch-points-profile-action button span {
  font-size: 12px;
}

.user-card__image-container {
  position: relative;
}

.user-card__check-mark {
  position: absolute;
  bottom: 3px;
  right: 12px;
  height: 20px;
}

.upcoming-room_check-mark {
  position: absolute;
  bottom: 0;
  right: -2px;
  height: 20px;
}

.upcoming-room_check-mark-small{
  bottom: 10px;
  height: 13px;
  top: 10px;
  right: -5px;
  position: absolute;
}

.search-bar_check-mark {
  position: absolute;
  bottom: 0;
  right: 10px;
  height: 20px;
}

.video-room_check-mark {
  position: absolute;
  bottom: 0px;
  right: 5px;
  height: 20px;
}

.room-details_check-mark {
  position: absolute;
  bottom: -3px;
  right: 6px;
  height: 15px;
}

.touch-points-user-container .touch-points-profile {
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid #ccc;
}

.Heading-moderator {
  color: #010039;
}

.back-arrow-btn {
  cursor: pointer;
}


@media only screen and (max-width: 900px) {
  .mainTopStatistics {
    text-align: center;
    display:flex;
    margin-bottom: 10px;
    flex-direction: column;
    width: 50%;
    .label {
      font-size: 15px;
      color:rgb(189, 189, 191);
      font-weight: 500;
    }
    .count {
      font-weight: 600;
    }
  }
  .moderator-wrapper {
    &.moderator-wrapper-connection {
      .followerList {
        padding-top: 50px;
        flex-direction: column;
      } 
    }
  }
  .mainContainer {
    padding: 20px 2px;

    .scrollbarY {
      height: 100%;
      overflow: hidden;

      &.left {
        display: contents;
      }
    }

    .mainMiddle {
      padding: 20px 0px;
    }

    .column1-main-box.profile {
      width: 100%;
      padding: 0px;
      padding-top: 50px;
    }
  }
  #middel-box-lable {
    text-align: left;
  }

}