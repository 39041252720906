.hostTakeBoxTexts {
   font-size:20px;
   margin-bottom: 10px;
   font-weight: 500;
   .editTakeBox {
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
   }
}


.hostOutside {
   width: 100%;
   border: 1px solid #E0E0E0;
   border-radius: 8px ;
   margin-bottom: 15px;
}

.hostTakeBox {
   width: 100%;
   border-radius: 12px ;
   display: flex;
   background-color: white;
   // align-items: center;
   &.notHost {
      flex-direction: column;
   }
   .hostTakeTopBox {
      padding: 15px 15px 5px 15px;
      display:flex;
      color: #1e3354;
      .profileImage & {
         margin-right: 5px;
         img {
            width: 36px;
            height: 36px;

         }
      }
      .hostName {
         padding-left: 5px;
         font-size: 12px;
         display: flex;
         gap: 5px;
         align-items: center;
         color: #979797;
         &:hover {
            cursor:pointer;
         }
         span {
            font-weight: 500;
            color:#1e3354;
            &:hover {
               cursor:pointer;
               text-decoration: underline;
            }
         }
         img {
            width: 18px;
            height: 18px;
         }
      }
      .genderAndAge {
         display: flex;
         color:#979797;
         align-items: center;
         margin-left: 5px;
         font-size: 12px;
      }
      .customHostBox {
         font-weight: 500;
         height: fit-content;
         padding: 0px 8px;
         font-size: 10px;
         color: #010039;
      }
   }
   .middleBox {
      min-height: 60px;
      padding: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
      color:#979797;
      word-break: break-word;
   }
   .resourcesBox {
      padding: 15px 0px;
      border-top: 1px solid #acacac;
      &.notHost {
         flex-direction: column;
         justify-content: center;
         padding: 0px;
         display: flex;
         align-items: center;
         border-top: none;
         border-left: 1px solid #E0E0E0;
      }
      .hostResources {
         width: 100%;
         margin-left: 0px !important;
         align-items: center;
         .resource {
            justify-content: space-between;
            display: flex;
            width: 100%;
            padding: 3px 15px;
            .url {
               font-size: 12px;
               width: 80%;
               display: flex;
               align-items: center;
               color: #121173;
               &:hover {
                  cursor: pointer;
                  text-decoration: underline;
               }
            }
            .buttons {
               min-width: 100px;
               width: max-content;
               gap: 5px;
               justify-content: center;
               align-items: center;
               display: flex;
               .customLike {
                  border-radius: 18px;
                  padding: 3px 10px;
                  border:1px solid #E5E5E5;
                  color: #27272A
               }
               .reportIcon {
                  height: 16px;
                  width: 16px;
                  cursor: pointer;
                  color: gray;
                  border-radius: 100%;
                  padding: 3px;
                  border: 1px solid gray;
               }
            }
         }
      }
   }
}

.userAcceptReject {
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: center;
   gap:20px;
   padding: 10px;
   border-top: 1px solid #acacac;
}

@media only screen and (max-width: 767px) {  
   .hostTakeBox {
      flex-direction: column;
      .resourcesBox {
         &.notHost {
            border-left:none;
            border-top: 1px solid #acacac;
         }
      }
   }  
}