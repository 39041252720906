/* .setting-main-div .MuiPaper-root.MuiDrawer-paper.Setting-drawerPaper-6.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0{
    z-index: 1000;
    margin-top: 65px;
    background-color: #fbfbfb;
  } */
.setting-main-div .MuiPaper-root.MuiDrawer-paper {
  top: 65px !important;
}

.list-item
  .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
  font-weight: 500 !important;
}

.MuiPaper-root.MuiDrawer-paper.Setting-drawerPaper-6.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  border-right: none;
}

.MuiDivider-root {
  height: 0px;
}

.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  box-shadow: none !important;
}

.MuiIconButton-root {
  color: #c7cad9 !important;
}

.MuiButtonBase-root.MuiListItem-root.Setting-selected-8:hover {
  background-color: #010138 !important;
  color: #fff !important;
}

.container-c1d {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-top: 1rem;
  align-items: center;
  padding: 0px 10px 0px 10px;
  background-color: #fff;
}

.container-c1d h3 {
  font-weight: 600;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #010138 !important;
}

.MuiSwitch-thumb {
  background-color: #010138 !important;
}

.feedback-container {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
}

.plus-icon {
  height: 15px;
  width: 15px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.PrivateNotchedOutline-root-37.MuiOutlinedInput-notchedOutline {
  border-radius: 10px;
}

.MuiOutlinedInput-root {
  border-radius: 10px;
}
.grey-para {
  color: #00000050;
  font-weight: 500;
}

.MuiSvgIcon-root.dropdown-icon {
  margin-right: 10px;
}

.textField {
  height: 30px;
}

.textarea-custom {
  height: 300px;
}
.textarea-custom .text-area .MuiInputBase-root textarea {
  height: 250px !important;
}

.delete-btn {
  background-color: transparent !important;
  color: #e70a0a !important;
  border: 1px solid #e70a0a !important;
  margin-top: 15px !important;
  box-shadow: none !important;
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.delete-btn:disabled {
  background-color: #ccc !important;
  pointer-events: none;
}

.deactivation-dropdown .dropdown-content {
  color: #7f8e9d !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.deactivation-dropdown .dropdown-content-scroll {
  max-height: 165px;
}
.terms-codtn-para {
  color: #979797;
  font-weight: 400;
}

/* modal  */

.alert-modal .MuiDialogActions-root {
  flex: 0 0 auto;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
}
.alert-modal .MuiButton-root.modal-cancel-btn {
  width: 250px;
  border-radius: 5px !important;
  font-weight: 600;
  font-size: 16px;
  color: #010138;
}
.alert-modal .MuiButton-root.modal-delete-btn {
  width: 250px;
  border-radius: 5px !important;
  font-weight: 600;
  font-size: 16px;
  background-color: #e70a0a;
  border: 1px solid #e70a0a;
  color: #fff;
}

.MuiPaper-rounded {
  border-radius: 10px;
}

.margin-bottom-custom {
  margin-bottom: 10px !important;
}

.list-item-settings .Setting-selected-20.MuiListItem-button:hover {
  text-decoration: none;
  background-color: #010138 !important;
  color: #fff !important;
}

.list-item-settings .Setting-selected-21.MuiListItem-button:hover {
  text-decoration: none;
  background-color: #010138 !important;
  color: #fff !important;
}

.otp-input-container > div {
  flex: 0.5;
}

@media screen and (max-width: 768px) {
  .feedback-container {
    width: 100%;
  }
}
