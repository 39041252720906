.UserEditProfile {
   margin-top: 100px;

   input {
      color: #334155;
   }
   textarea {
      color: #7F8E9D;
   }
   .disabled-input, .phone-input {
      background-color: #F1F1F1;
   }

   .gender-input {
      font-weight: normal !important;
   }
   
   .input-field {
      font-size: 16px;
      color: #334155;
   }
   .editProfileBack {
      cursor: pointer;
      font-size: 24px;
      margin-bottom: 10px;
      display: flex;
      font-weight: 600;
      padding-bottom: 5px;
      align-items: center;
      padding-left: 0px;
      text-align: left;
      img {
         margin-right: 17px;
      }
   }

   .selected-chip {
      width: 100%;
   }

   .loadingMask {
      z-index: 1;
      left: -10px;
      position: absolute;
      width: 100%;
      backdrop-filter: blur(8px);
      height: calc(100vh - 70px);
      background-color: transparent;
   }

   .saveChanges {
      // margin-bottom: 50px;
      // padding: 15px 25px;
      // color: #150c44;
      // border-radius: 12px;
      // margin-top: 20px;
      // cursor: pointer;
      // text-align: center;
      // margin-left: auto;
      // margin-right: auto;
      // font-weight: 600;
      // font-size: 20px;
      // background-color: #ffcb00;
      // transition: .5s;
      margin-bottom: 50px;
      padding: 15px 25px;
      width: 50%;
      color: #150c44;
      border-radius: 12px;
      margin-top: 20px;
      cursor: pointer;
      text-align: center;
      margin-right: auto;
      font-weight: 600;
      font-size: 20px;
      background-color: #ffcb00;
      transition: .5s;

      &.disabled {
         background-color: #aaa;
         color: #888;
         cursor: default;

         &:hover {
            background-color: #aaa;
         }
      }

      &:hover {
         background-color: #ddba00;
      }

      p {
         margin: 0px;
      }
   }

   .outSide {
      // margin-top: 100px;
      width: 100%;

      .col {
         &.social {

         }
      }

      .PerosnalInformationFormInside {
         margin-top: 20px;

         .selected-subcategories-wrapper {
            height: 140px;
            overflow: auto;
            margin-top: 5px;
         }

         .subAndCountryBox {
            height: 111px;
         }

         .colForSocial {
            display: flex;
            border: 1px solid #cccbcb;
            border-radius: 8px;
            padding: 0px 0px 0px 15px;
            align-items: center;
            margin-top: 5px;

            .inputFull {
               margin-top: 15px;

               ::before {
                  border-bottom: none !important;
               }
            }

            img {
               margin-right: 20px;
               height: 25px;
               width: 25px;
            }

            .facebook {

         
            }

            .crossIco {
               margin-left: 20px;
               height: 18px;
               width: 18px;
            }
         }

         label {
            font-weight: 500;
            color: #222255;
            font-size: 18px;
         }

         .small-label {
            font-size: 14px;
            color: #7F8E9D;
         }

         p {
            font-weight: 500;
            color: #222255;
            font-size: 18px;
         }

         .MuiFormControl-marginNormal {
            margin-top: 0px !important;
         }

         .AccountCircleIcon {
            cursor: pointer;
         
            img {
               background-color: #dedede;
               border-radius: 100%;
               object-fit: cover;
               padding: 3px;
               width: 104px;
               height: 104px;
            }

         }

         .checkInfoButton {
            font-size: 16px;
            border-radius: 12px;
            padding: 15px !important;
            border: 1px solid #7F8E9D;
            background-color: #fff;
            color: #7F8E9D;
            box-shadow: none;
            text-align: center;

            &.active {
               color: #150c44;
               border-color: #150c44;
               background-color: #dcdcdc;
               // background: #fff;
               text-transform: initial;
            }
            &:hover {
               border: 1px solid #010039;
            }
         }

         .checkInfoText {
            font-size: 16px !important;
            background-color: #fff !important;
            color: #cccbcb !important;
            box-shadow: none !important;
            height: 62px !important;
            transition: .5s;

            .MuiInputBase-formControl {
               height: 62px !important;
            }

            fieldset {
               height: 68px !important;
               border: 1px solid #cccbcb !important;
               border-radius: 8px;
            }
         }

         .fullForm {
            width: 100%;

            .addSpace {
               height: 50px;
               width: 100%;
            }

            .wordsFlex {
               display: flex;
               flex-direction: column;
               gap: 15px;
            }

            .colModerator {
               position: relative;
            }

            .moderatorAdd {
               position: absolute;
               right: 0px;
               top: 10px;

               &.cross {
                  img {
                     cursor: pointer;
                     width: 16px;
                     height: 16px;
                  }

               }

               img {
                  cursor: pointer;
                  width: 21px;
                  height: 21px;
               }

            }

            .pInfoLabel {
               justify-content: space-between;
               display: flex;
               color: #010039;
               margin-top: 18px;
               margin-bottom: 5px;
               font-weight: 500;
               width: 100%;
               font-size: 18px;

               .documentAdd {
                  img {
                     cursor: pointer;
                     width: 20px;
                     height: 20px;
                  }
               }

            }

            .uploadFileDiv {
               display: flex;
               width: 100%;
               margin-top: 10px;
               border-radius: 8px;
               border: 1px solid #bab1b1;
               background-color: white;
            }

            .cancelDiv {
               display:flex;
               justify-content: center;
               align-items: center;
               cursor: pointer;
            }

            .inputFull {
               border-radius: 12px 0px 0px 12px;
               border: none !important;

               .MuiButton-root {
                  padding-left: 6vw !important;
                  border-radius: 12px 0px 0px 12px !important;
               }

               &.doc {
                  
               }
            }

         }


         .MuiOutlinedInput-root {
            border-radius: 10px;
            background-color: white;

            &.Mui-focused {
               box-shadow: none;
               border: none !important;
            }
         }

         .MuiOutlinedInput-input {
            padding: 16.5px 14px;
         }

         .text-area {
            height: auto !important;
            margin-bottom: 0px !important;
            max-height: none;
         }
      }
   }
}

.cardHeader {
   border-bottom: 1px solid #ffcb00;
   font-size: 24px;
   padding-top: 30px;
   font-weight: 500;
   color: #ffcb00;

   &.moderators {
      border-color: #dcdcdc;
      text-align: center;
      color: #000000;
   }
}


.pInfoText {
   width: 100%;
   display: flex;
   flex-direction: column;
}

.pInfotextField {
   border-radius: 12px;
   padding-bottom: 20px;
}

.pInfotextField fieldset {
   border-radius: 12px;
}

.pInfoCheckButtons {
   display: flex;
   gap: 10px;
   flex-wrap: wrap;
}

.areYouSureModal {
   display: flex;
   position: fixed;
   z-index: 1200;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   overflow: auto;
   background-color: rgba(0, 0, 0, 0.5);
   backdrop-filter: blur(10px);

   .backgroundDiv {
      margin: auto auto;
      padding: 20px;
      border: 1px solid #888;
      border-radius: 12px;
      width: 400px !important;
      background-color: #fff;

      .mainText {
         color: #aaa;
         font-weight: 500;
         width: 100%;
         font-size: 20px;
         margin-bottom: 25px;

         .boldColored {
            color: #222255;
         }

      }

      .yesNoBox {
         display: flex;
         width: 100%;
         gap: 4%;

         .yes {
            background-color: #222255;
            width: 48%;
            padding: 14px;
            border-radius: 12px;
            border: none;
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
            transition: .3s;

            &:hover {
               background-color: #0e082c;
            }
         }

         .no {
            cursor: pointer;
            font-size: 18px;
            border-radius: 12px;
            color: #aaa;
            font-weight: 500;
            border: 1px solid #cccbcb;
            background-color: #fff;
            padding: 14px;
            width: 48%;
            transition: .3s;

            &:hover {
               background-color: #cccbcb;
            }
         }
      }
   }
}

@media only screen and (max-width: 900px) {

   .UserEditProfile {
      margin-top: 30px;

      .editProfileBack {
         margin-left: 25px;
         font-size: 22px;
      }

      .saveChanges {
         width: 100%;
         margin: 16px 16px;
      }

      .outSide {
         margin-top: 30px;

         .cardHeader {
            text-align: center;
            font-size: 22px;
         }

         .cardHeader {
            margin-bottom: 0px !important;
         }


         .PerosnalInformationFormInside {

            label {
               font-size: 16px;
            }

            .earnPoint-para {
               width: 100%;
            }

            .checkInfoButton {
               width: 100%;
               font-size: 16px;
            }

            .small-label {
               font-size: 14px;
            }

            .fullForm {
               padding: 0px 16px;

               .pInfoLabel {
                  font-size: 18px;
               }
            }

            .subAndCountryBox {
               height: auto;

               .selected-subcategories-wrapper {
                  height: auto;
               }
            }
         }
      }

   }
}