@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500&display=swap");

/* * {
  padding: 0;
  margin: 0;
  font-family: "Baloo Bhai 2", cursive;
  overflow-x: hidden;
} */
.moderator-wrapper {
  padding-top: 90px;
  background-image: url(/static/media/welcome_background_image.275f2cb9.png),
    url(/static/media/welcome_background_image.275f2cb9.png),
    url(/static/media/welcome_background_image.275f2cb9.png);
  background-repeat: no-repeat;
  background-position: 40px 28px, 170px 1599px, 1394px 768px;
  background-size: 40px 28px, 170px 1599px, 1394px 768px;
}

.moderator-wrapper-connection {
  background-position: 10px 40px, 110% 700px !important;
  background-size: 500px 500px, 500px 500px !important;
}

.moderator-wrapper .categories-aside {
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  background: #fff;
}

.moderator-wrapper .sidebar {
  box-sizing: border-box !important;
  max-height: calc(100vh - 64px) !important;
  min-height: calc(100vh - 64px) !important;
  overflow-x: auto !important;
  padding: 0px !important;
  margin-top: 12px;
  position: sticky;
  top: 66px;
}
.scroll-bar::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar::-webkit-scrollbar-track {
  background-color: lightgrey !important;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background: #0000008f;
  border-radius: 5px;
}

.moderator-wrapper .categories-head {
  padding: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #c7cad9;
  font-size: 18px;
}

.moderator-wrapper div.buttons-div-moderator {
  padding-bottom: 15px !important;
}

.moderator-wrapper .buttons-div-moderator.bBottom .MuiButton-contained {
  height: 30px !important;
}

.moderator-wrapper .categories-head * {
  margin: 0px;
}

.moderator-wrapper .daf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p15 {
  padding: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

/* .mb20 {
  overflow:hidden
}
.mb30 {
  margin-bottom: 30px;
} */

/* .moderator-wrapper .bBottom {
  border-bottom: 1px solid rgb(192, 192, 192);
} */

.moderator-wrapper .data {
  padding: 15px;
  /* border-bottom: 1px solid rgb(192, 192, 192); */
}
.moderator-wrapper .daf.bBottom:last-child {
  border-bottom: none !important;
}

.moderator-wrapper .daf.bBottom:first-child {
  border-top: 1px solid #c7cad9;
}

.selected-category {
  background: #fffae6;
}

.flex-sb-center {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.flex-sb {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}
/* .bBottomYellow {
  border-bottom: 2px solid #ffcb00;
} */

.pointer {
  cursor: pointer;
}

.moderator-wrapper .buttons-div-moderator {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px !important;
}

.moderator-wrapper .btn-Category-inner {
  box-shadow: none !important;
  background: #ffcd2f !important;
}

.moderator-wrapper .btn-age {
  width: 100%;
  justify-content: start !important;
  padding-left: 20px;
  /* border-bottom: 1px solid #E5E5E5; */
}

.moderator-wrapper .btn-age.MuiButton-root {
  border-radius: 0px !important;
}

.moderator-wrapper .btn-age-outter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  margin-bottom: 1px;
  border-top: 1px solid #e5e5e5;
}
.moderator-wrapper .btn-age {
  padding: 10px 15px !important;
}

.moderator-wrapper .heading-moderator {
  font-size: 25px;
  font-weight: 600;
  margin-top: 0px;
  margin-left: 15px;
}

.moderator-wrapper .list-item-outter {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-bottom: 20px;
  background: #fff;
}
.moderator-wrapper .list-first-div {
  margin-top: 12px;
}

.moderator-wrapper .list-first-div .span-after-username {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #00000050 !important;
}

.moderator-wrapper .list-first-div > span {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #010138;
}

.moderator-wrapper .list-second-div {
  color: #00000050;
}

.moderator-wrapper .rooms {
  padding: 0px 5px;
}

.moderator-wrapper .rooms svg {
  font-size: 11px;
  /* padding: 0px 7px 0px 16px; */
  position: relative;
  margin: 0px 5px;
  top: 1px;
  color: #00000030;
}

.moderator-wrapper .list-item-content p {
  color: #c1c1c1;
  font-weight: 500;
  width: 80%;
  padding-top: 10px;
}

.moderator-wrapper .list-item-outter button {
  background: #010138;
  color: #fff;
  border: 1px solid #000;
  border-radius: 30px !important;
  padding: 5px 20px;
}

.moderator-wrapper .buttons-div-moderator.null {
  background: unset;
  box-shadow: none;
  border-radius: unset;
}

.icon-style-connection {
  height: 40px;
  width: 40px;
  right: 200px;
  position: absolute;
  top: 20px;
  cursor: pointer;
}

.message-icon-style-connection {
  height: 18px;
  width: 18px;
  right: 210px;
  position: absolute;
  top: 32px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .moderator-wrapper {
    padding: 2rem;
  }
}
