.terms-heading {
    display: flex;
    margin: auto;
    color: #010138;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
}

.terms-content {
    margin: 15px 0px;
    border: 1px solid #0005;
    padding: 20px;
    border-radius: 10px;
}