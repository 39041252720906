.editTakeOutside {
   width: 100%;
   border: 1px solid #c7cad9;
   border-radius: 12px ;
   padding: 25px;
   .divColum {
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap:20px;
      display: flex;
      .headerText {
         width: 100%;
      }
      .textArea {
         width: 100%;

      }
      .resourcesOutside {
         width: 100%;
         .resourcesTop {
            align-items: center;
            display:flex;
            justify-content: space-between;
            img {
                  cursor: pointer;
                  margin-left: auto;
                  margin-right: 5px;
                  width: 18px;
                  height: 18px;
            }
            
         }
         .errorBox {
            margin-top: 5px;
            font-size: 12px;
            color: red;
            font-weight: 400;
         }
         .resourceList {
            .resources {
               .resourceWrappeer {
                  width: 100%;
                  margin:10px 0px;
                  align-items: center;
                  display:flex;
                  justify-content: space-between;
                  .resourceText {
                     white-space: nowrap;
                     overflow: hidden !important;
                     text-overflow: ellipsis;
                     width: 80%;
                  }
                  .buttons {
                     display: flex;
                     font-size: 20px;
                     justify-content: flex-end;
                     .clearIcon {
                        cursor:pointer;
                        margin-left: 5px;
                        border-radius: 100%;
                        border:1px solid red;
                        color:red;
                        padding: 2px;
                        font-size: 20px;
                     }
                  }
               
               }
               .addNewResource {
                  border: 2px solid #c7cad9;
                  padding: 15px;
                  border-radius: 8px;
                  width: 100%;
                  display: flex;
                  &:focus {
                     border-color: #ffcb00;
                  }
                  .textBox {
                     width: 100%;
                     outline: none;
                     border:none;
                  }
                  .MuiSvgIcon-root{
                     cursor: pointer;
                     margin-left: 5px;
                     width: 22px;
                     height: 22px;
                  }
                  img {
                     cursor: pointer;
                     margin-left: 5px;
                     width: 18px;
                     height: 18px;
                  }
               }
            }
         }
      }
      .publicAnonBox {
         width: 100%;
      }
      .bottomText {
         padding: 0px 40px;
         color: #a2a2a2;
         text-align: center;
      }
      .saveCancelButtonBox {
         .button {
            cursor: pointer;
            font-size: 18px;
            width: 100%;
            text-align: center;
            padding: 15px;
            border-radius: 12px;
            background-color: #ffcb00;
            color: #1e3354;
            font-weight: 600;
            transition: .5s;
            &.cancel {
               margin-top: 10px;
               background-color: #fff ;
               text-decoration: underline;
               &:hover {
                  background-color: #ededed;
               }
            }
         }
      }
   }
}

.sliderBase {
   width: 100%;
   height: 50px;
   padding: 5px;
   border-radius: 25px;
   background-color: #f9f9f9;
   display: flex;
   font-size: 16px;
   font-weight: 600;
   .insideButtons {
      width: 50%;
      height: 100%;
      color: #a2a2a2;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: .5s;
      &.active {
         color:#1e3354;
         cursor: default;
         background-color: #fff;
      }
   }
}

.warningBoxWrapper {
   width: 100%;
   border-radius: 12px;
   border:1px solid #a2a2a2;
   display: flex;
   align-items: center;
   color:#a2a2a2;
   gap: 10px;
   padding: 15px;
   .warningIconWrapper {
      border-radius: 100%;
      border: 2px solid #1e3354;
      color:#1e3354;
      padding: 3px;
   }
}

.smallTextArea {
   height: 100px;
   margin-top: 10px;
   .textArea {
      height: 50px !important;
      .MuiOutlinedInput-root {
         border-radius: 12px;
      }
   }
}

@media only screen and (max-width: 767px) { 
   .editTakeOutside {
      padding: 10px;
      .divColum {
         .resourcesOutside {
            .resourceList {
               .resources {
                  .resourceWrappeer {
                     .resourceText {
                        font-size: 14px;
                     }
                     .buttons {
                        display: flex;
                        font-size: 18px;
                     }
                  }
                  .addNewResource {
                     border: 1px solid #c7cad9;
                     padding: 10px;
                     border-radius: 12px;
                     .textBox {
                        width: 100%;
                        outline: none;
                        border:none;
                     }
                     .MuiSvgIcon-root{
                        cursor: pointer;
                        margin-left: 5px;
                        width: 22px;
                        height: 22px;
                     }
                     img {
                        cursor: pointer;
                        margin-left: 5px;
                        width: 18px;
                        height: 18px;
                     }
                  }
               }
            }
         }
         .publicAnonBox {
            width: 100%;
         }
         .bottomText {
            font-size: 14px;
            padding: 0px 10px;
            color: #a2a2a2;
            text-align: center;
         }
         .saveCancelButtonBox {
            .button {
               font-size: 16px;
               padding: 10px;
               &.cancel {
                  margin-top: 10px;
                  background-color: #fff ;
                  text-decoration: underline;
                  &:hover {
                     background-color: #ededed;
                  }
               }
            }
         }
      }
   }
   
   .sliderBase {
      font-size: 13px;
   }
   
   .warningBoxWrapper {
      font-size: 13px;
      .warningIconWrapper {
         border-radius: 100%;
         border: 2px solid #1e3354;
         color:#1e3354;
         padding: 3px;
      }
   }
   
   .smallTextArea {
      height: 80px;
      margin-top: 10px;
      .textArea {
         height: 30px !important;
         .MuiOutlinedInput-multiline {
            padding:10px;
            font-size: 14px;
         }
         .MuiOutlinedInput-root {
            border-radius: 12px;
         }
      }
   }

}