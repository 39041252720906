.backgroundImage {
  background-image: url(../../assets/Y.svg), url(../../assets/Y.svg);
}

.rulesOfEngagement {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);

  &.active {
    display: block;
    position: fixed;
    z-index: 1200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }

  .rulesOfEngagementContent {
    background-color: #fefefe;
    margin: 3% auto;
    flex-direction: column;
    align-items: center;
    border: 1px solid #888;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    position: relative;
    min-width: 50% !important;
    max-width: 1000px !important;
    background-size: 350px, 350px;
    background-position: left -50px top -30px, right -50px bottom -50px;
    background-repeat: no-repeat;
    padding: 50px 120px;

    /* height: 500px; */

    .label {
      width: 100%;
      text-align: center;
      margin-bottom: 40px;
      font-size: 36px;
      font-weight: 500;
    }

    .smallLabel {
      width: 60%;
      color: #232344;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .closeButton {
      cursor: pointer;
      position: absolute;
      top: 40px;
      right: 40px;
      border: 2px solid #323232;
      border-radius: 100%;
      padding: 18px;

      img {
        width: 15px;
        height: 15px;
      }
    }

    .list {
      width: 60%;
      font-size: 18px;
      font-weight: 500;

      .col {
        margin-bottom: 25px;
        color: #888;

        &:nth-child(n + 5) {
          margin-bottom: 0px;
        }

        .number {
          margin-right: 10px;
        }

        .blackText {
          color: #222222;
        }

        .whiteText {
          &.list {
            margin-top: 10px;
            list-style: disc !important;
            font-size: 16px;
            margin-left: 20px;
            display: list-item !important;
            width: 100%;
            margin-bottom: 5px;
          }
        }
      }
    }

    .checkboxBox {
      width: 60%;
      margin-top: 10px;
      font-size: 18px;
      align-items: center;
      font-weight: 500;
      color: #888;
      display: flex;
      justify-content: flex-start;
      cursor: pointer;

      input {
        margin-right: 10px;
      }
    }

    .errorBox {
      margin-bottom: 45px;
      height: 20px;
      width: 60%;
      color: red;
      font-size: 16px;
    }

    .enterButton {
      cursor: pointer;
      width: 60%;
      margin-top: 5px;
      padding: 12px;
      border: none;
      background-color: #232344;
      color: #fff;
      font-size: 20px;
      border-radius: 12px;
      text-align: center;
      margin-bottom: 60px;
      font-weight: 600;
    }
  }
}

// Styles for Late Join Screen
.title {
  color: #28272a !important;
  font-size: 2rem;
  margin-bottom: 2.5rem;
  font-weight: 500;
}

.img-container {
  img {
    max-height: 22rem;
    max-width: 22rem;
    margin-bottom: 4rem;
  }
}

.message {
  color: #9e9cab;
  font-weight: 600;
  font-size: 1rem;
}

.back-btn {
  background: #010039;
  color: #fff;
  width: 65%;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 2rem;
  border: none;
  outline: none;
  padding: 0.7rem 0;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all .3s ease;
}

.back-btn:hover {
  box-shadow: 0 5px 10px rgba(1, 0, 57, 0.3);
}

.center {
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

@media only screen and (max-width: 900px) {
  .rulesOfEngagement {
    &.active {
      z-index: 9999;
      overflow: auto;
      height: 100vh;
    }

    .rulesOfEngagementContent {
      margin: 0px;
      padding: 40px 20px !important;
      border-radius: 0px;

      .closeButton {
        top: 20px;
        right: 20px;
        padding: 6px;
      }

      .smallLabel {
        width: 100%;
      }

      .list {
        width: 100%;
      }

      .enterButton {
        width: 100%;
      }

      .checkboxBox {
        width: 100%;
      }
    }
  }
}