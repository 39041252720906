.verification-outer {
  width: 60%;
  border-radius: 15px;
  margin: 60px auto;
  height: max-content;
  text-align: center;
  max-width: 95vh;
}

.cancel-out {
  text-align: right;
  z-index: 1;
}

.cancel-out .cancel-icon {
  margin: 15px !important;
  height: 50px;
  width: 50px;
  font-size: 35px !important;
  cursor: pointer;
}

.otp-container {
  margin: 0px 20%;
}

.otp-container p {
  font-size: 15px !important;
  color: #00000052 !important;
  font-weight: 400 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-container input {
  width: 50px;
  margin: 10px 8px;
  text-align: center;
  font-size: 25px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #0000006e;
  -webkit-appearance: textfield;
}

.submit-button-div {
  width: 100%;
  margin: 0px auto;
}

.submit-button-div .submit-btn {
  width: 100%;
  color: #fff !important;
  background: #150c44 !important;
  font-size: 15px;
  margin: 15px 0px;
  border-radius: 10px;
  box-shadow: unset;
  text-transform: unset;
}

.setup-profile {
  margin: 0px auto;
  width: 100%;
  display: block !important;
}

.setup-profile .setup-submit {
  width: 50%;
}

.bottom-text .text-small {
  font-size: 16px;
}

.bottom-text .text-big strong {
  /* color:#150c44; */
  font-weight: 500;
}

.MuiAlert-root {
  padding: 6px 35px !important;
}

.MuiAlert-filledSuccess {
  color: #000 !important;
  background-color: #95d17c !important;
}

.MuiPaper-elevation6 {
  box-shadow: none !important;
}

.MuiSnackbar-anchorOriginTopCenter {
  top: 75px !important;
}

.MuiAlert-root {
  border-radius: 10px !important;
}

.MuiAlert-filledError {
  background-color: #ff5e60 !important;
}

.padding-24 {
  padding: 24px;
}

.MuiStepLabel-label {
  color: rgb(0 0 0 / 22%) !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: #150c44 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  color: #150c44 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.MuiOutlinedInput-root .dropdown-value {
  padding: 14px;
  color: #000;
}

.MuiStepConnector-lineHorizontal {
  border-top-style: solid !important;
  border-top-width: 4px !important;
}

.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiPaper-elevation0 .MuiStepLabel-iconContainer .MuiSvgIcon-root {
  font-size: 30px !important;
}

.MuiStepConnector-line {
  display: block !important;
  /* border-color: #150c44 !important; */
}

.verification-inner .card-header span {
  font-size: 29px !important;
  font-weight: 500 !important;
  color: #150c44;
}

.verification-inner .AccountCircleIcon {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  padding: 1px;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.verification-inner .card-contain {
  text-align: left;
  color: #150c44;
  font-weight: 500 !important;
}

.verification-inner .MuiOutlinedInput-root {
  position: relative;
  border-radius: 12px !important;
  margin: 5px 0px 5px 0px !important;
}

.blur-placeholder .MuiInputBase-input {
  opacity: 0.5;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #ffd33a !important;
}

.selected-chip {
  margin-top: 5px;
  border-radius: 10px !important;
  background: #150c44 !important;
  color: #fff !important;
  width: 270px;
}

.verification-outer {
  overflow: hidden;
}

.verification-inner.MuiPaper-elevation5 {
  box-shadow: none !important;
}

.inner-grid .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiPaper-elevation5 {
  border-radius: 20px !important;
}

.small-label {
  font-size: 11px;
  opacity: 0.5;
  color: #000;
}

.card-contain .outer-grid .MuiGrid-root {
  margin-bottom: 10px;
}

.welcome-inner {
  padding: 50px;
  background-color: #fff;
  background-image: url('../../assets/welcome_background_image.png'),
    url('../../assets/welcome_background_image.png');
  background-repeat: no-repeat;
  /* background-position:  left top, 450px 320px;
    background-size: 40% 40% , 45% 45% !important; */
  background-position: 0px -28px, 658px 481px;
  background-size: 29% 37%, 34% 43% !important;
}

.outer-div {
  background: transparent;
  margin: 0% 16%;
  font-family: 'Roboto', sans-serif;
}

.paragraph {
  font-size: 20px;
  color: #7d7b7b;
}

.submit-btn-welcome {
  width: 50%;
  margin-top: 15px !important;
  color: #fff !important;
  background: #150c44 !important;
  text-transform: initial !important;
}

.heading {
  font-size: 15px;
  font-weight: 500;
}

.welcome-inner .heading h2 {
  font-weight: 500;
  font-size: 29px;
}

.onboard-heading p {
  font-size: 30px;
  font-weight: 500;
  margin: 15px;
  margin-top: 20px;
}

.onboard-heading h3 {
  font-weight: 400;
  font-size: 20px;
  margin-top: 29px;
  color: #010038fa;
}

.onboard-heading .onboard-subheading {
  font-size: 17px !important;
  color: gray !important;
  text-align: justify;
}

.noPadding .MuiStepper-root {
  display: flex;
  padding: 0px !important;
}

.h3-tag {
  font-size: 20px;
  /* margin-bottom: -20px; */
  font-weight: 600;
  margin-bottom: -9px;
}

.earnPoint-para p {
  font-size: 14px;
  color: #aaa4a4;
  font-weight: 500;
}

.submit-btn-onboard {
  width: 100%;
  border-radius: 10px !important;
  margin-top: 30px;
  color: #fff !important;
  background: #150c44 !important;
  text-transform: initial !important;
}

.skip-btn {
  margin: 30px 0px 75px 0px;
  font-size: 13px;
  text-decoration: underline;
  font-weight: 400;
  color: #aaa4a4;
  cursor: pointer;
}

/* Onboarding css */
.div-container {
  margin: 0px auto;
  width: 50%;
}

.div-container .textField {
  margin: 9px 0px;
  border-radius: 10px !important;
}

.div-container .textField .MuiInputBase-root {
  height: 50px;
}

.div-container .textField .MuiOutlinedInput-root {
  position: relative;
  border-radius: 10px !important;
}

.dot-icon {
  margin: 20px;
  margin-top: 50px;
}

.inactive-stepper {
  color: #80808070;

  width: 25px !important;
  height: 16px !important;
}

.active-stepper {
  font-size: 12px;
  color: #150c44;
  width: 25px !important;
  height: 16px !important;
}

.bottom-text {
  margin: 15px 0px;
  font-size: 12px;
  color: #808080c7;
  font-weight: 500;
}

.buttons-div {
  display: flex;
  justify-content: center;
}

.buttons-div .btn-multiple {
  width: max-content;
  border-radius: 10px;
  margin: 10px;
  color: #8e8c8c;
  background: #fff;
  text-transform: initial;
  border: 1px solid #cccbcb;
  box-shadow: none;
}

.buttons-div .btn-multiple.active {
  color: #fff !important;
  background: #150c44 !important;
}

.earnPoint-para .addtext-input .MuiInputBase-root {
  height: 48px !important;
  border-radius: 10px !important;
}

.addtext-input input {
  max-width: 275px;
}

/* .text-area{
    width: 100%;
    margin-bottom: 30px !important;
    height: 130px;
}
.text-area .MuiInputBase-root{
    padding: 10px !important;
    border-radius: 10px !important;

}

.text-area .MuiInputBase-root textarea{
    height: 150px !important;
} */

.text-area .MuiFormHelperText-root {
  text-align: right;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  border-radius: 10px !important;
  max-height: 180px !important;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  border-bottom: 1px solid #aaa;
  color: #aaa;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button input svg {
  color: #aaa;
}

.MuiList-root .MuiMenu-list .MuiList-padding {
  border-radius: 10px !important;
  max-height: 175px !important;
}

.MuiMenuItem-root {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.height-fill-webkit {
  height: 700px !important;
}

.MuiChip-root.selected-chip.MuiChip-deletable {
  display: flex !important;
  justify-content: space-between !important;
}

.MuiChip-root {
  height: 40px !important;
  margin-bottom: 5px !important;
}

.MuiButton-root {
  padding: 10px 37px !important;
}