@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');

* {
    font-family: "Poppins" !important;
    box-sizing: border-box;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
}

.react-tel-input {
    margin-top: 5px;
}

.cursor {
    cursor: pointer;
}

.react-tel-input .form-control {
    width: 100%;
}

.main-container {
    width: 1000px;
    border-radius: 15px;
    margin: 60px auto;
    overflow: hidden;
    height: max-content;
}

.img {
    width: 100%;
    margin: 0px auto;
}

.cancel-icon {
    margin: 16px !important;
    height: 45px;
    width: 45px;
    font-size: 35px !important;
    cursor: pointer;
}

.main-div {
    padding: 40px;

    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.cancel-out {
    position: absolute;
    right: 0px;
    z-index: 1;
}

.heading_signup {
    padding: 0px;
    font-size: 36px;
    margin-top: 0px;
    font-weight: 500 !important;
}

.h2 {
    padding-bottom: 10px;
    font-size: 36px;
    margin-top: 0px;
}

.label {
    font-weight: 500;
    color: #150c44 !important;
}

.labul {
    margin-left: 20px;

}

.error {
    margin-left: 10px;
    color: red;
    display: block;
    font-size: 12px;
    font-weight: 500;
    min-height: 18px;
}

.checkbox-div {
    display: flex;
    /* align-items: flex-start; */
    /* margin-bottom: 20px; */
    /* flex-direction: column; */
    justify-content: center;
}

/* .checkbox {
    width: 18px;
    height: 30px;
    vertical-align: middle;
    margin-right: 7px;
} */

.terms {
    font-size: 13px;
    font-weight: 500;
    color: #97a4af;
}

.a1 {
    color: #150c44;
    font-weight: 500;
    text-decoration: underline;
}

/* .checkbox input{
    border-radius: 50% !important;
} */

.div-container .textField {
    margin: 9px 0px 25px !important;
    border-radius: 10px !important;
}

.checkbox-div .container {
    display: block;
    position: relative;
    padding-bottom: 0px;
    padding-left: 30px;
    margin-bottom: 6px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 1;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: solid 1px #000000;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.checkbox-div input[type="checkbox"]::after {
    left: 27%;
    top: 11%;
    width: 37%;
    height: 69%;
}

.a2 {
    color: #150c44;
    font-weight: 500;
    text-decoration: underline;
}

.btn {
    width: 100%;
    color: #fff !important;
    background: #150c44 !important;
    font-size: 15px;
    margin: 15px 0px;
    border-radius: 10px;
    box-shadow: unset;
    text-transform: unset;
}

.h4 {
    margin: 30px;
    font-size: 20px;
    font-family: none;
    color: #00000059;
}

.a3 {
    color: #150c44;
    font-weight: bold;
}

.grid-image {
    background: #FFCB00;
    display: flex;
    align-content: center;
    align-items: center;
}

.image-logo {
    text-align: center;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 10px !important;
}

.MuiButton-root {
    text-transform: none !important;
    border-radius: 10px !important;
}

.inputFull {
    width: 100%;
    border: 1px solid #bab1b1;
    border-radius: 8px;
    opacity: 0.8;
    height: 50px;
    padding: 10px 10px;
    background-color: #FFFFFF;
    padding-left: 10px;
}