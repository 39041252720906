.dateSelectedClass {
  background-color: #ffcb00 !important;
  color: #fff !important;
}

.create_room_popup .dropdown-content {
  font-size: 14px;
}

.create_room_popup > div:nth-child(1) {
  height: calc(100vh - 84px);
  overflow-x: scroll;
  border: 1px solid #ccccc9;
}


.your-take
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
  border: solid 1px #5555;
}
.create_room_popup .Mui-checked .MuiSwitch-thumb {
  background-color: #fff !important;
  border: 1px solid #ccc;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  opacity: 1 !important;
}

.create_room_popup .MuiSwitch-thumb {
  background-color: grey !important;
}

.refill-wallet {
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: #101038;
  text-decoration: underline;
  cursor: pointer;
  padding: 20px;
}

.time-picker .time-picker-ul {
  margin: 1px;
  padding: 0px;
  list-style-type: none;
}

.time-picker .time-picker-ul li {
  padding: 3px 10px;
}

.room-details-time span.last-word {
  background: white;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .create_room_popup > div:nth-child(1) {
    overflow-x: hidden;
    border: none;
  }

  .create_room_popup::-webkit-scrollbar,
  .create_room_popup div::-webkit-scrollbar {
    display: none;
  }
}
