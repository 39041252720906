.subcategoriesBox {
   width: 100%;
}

.subcategoriesBackButton {
   font-size: 22px;
   display: flex;
   font-weight: 400;
   cursor: pointer;
   padding-bottom: 15px;
   align-items: center;
   padding-left: 0px;
   text-align: left;
}

.subcategoriesBackButton img {
   margin-right: 17px;
}

.subcategoriesLabel {
   text-align: left;
   font-size: 20px;
   font-weight: 500;
   margin-bottom: 15px;
}

.subcategoriesList {
   height: calc(100vh - 220px);
   overflow: auto;
   border: 1px solid #c7cad9;
   width: 100%;
   background-color: #fff;
   border-radius: 12px;
   padding-top: 10px;

}

.subcategoriesListBox {
   padding: 13px;
   border-radius: 10px;
   margin-bottom: 10px;
   cursor: pointer;
   display: flex;
   align-items: center;
   height: 50px;
   transition: .3s;
}

.subcategoriesListBox.active {
   background-color: #222255;
   color: #fff;
}

.subcategoriesListBox.active .subcategoriesName span {
   color: #fff !important;

}

.subcategoriesName {
   color: #fff;
   display: flex;
   align-items: center;

}

.subcategoriesName span {
   color: #222255;
   margin-left: 10px;
   font-weight: 600;
   display: flex;
   align-items: center;

}

.subcategoriesName img {
   width: 40px;
   height: 40px;
   margin-left: 15px;
   object-fit: cover;
   border-radius: 4px;
}

/* SUBCATEGORY*/

.subcategoryBreadcrumb {
   height: 78px;

   .MuiSkeleton-text {
      transform: scale(1, 1);
   }
}

.subcategoryBreadcrumb li {
   color: #222222;
}

.subcategoryBreadcrumb p {
   font-size: 20px !important;
}

.subcategoryBreadcrumb a {
   font-size: 20px !important;
   cursor: pointer;
   color: #222222;
}

.topicAndRoomCountLabel {
   justify-content: center;
   align-items: center;
   display: flex;
   margin: 0px 10px 10px 10px;
   font-size: 18px;
   color: #222255;
   font-weight: 600;
   gap: 5px;

   span {
      font-size: 16px;
      background-color: ffeb3b;
      min-height: 30px;
      width: fit-content;
      aspect-ratio: 1 / 1;
      height: fit-content;
      padding: 7px;
      min-width: 30px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
   }
}

.subcategoryPostOutisde {
   width: 100%;
   padding: 30px 25px;
   height: 180px;
}

.subcategoryPost {}

.subcategoryName {
   font-weight: 500;
   text-align: left;
   font-size: 24px;
   color: #222255;
}

.subcategoryBannerBox {
   margin-bottom: 20px;
   width: 100%;
   min-height: 360px; 
   border-radius: 12px;
   position: relative;
}

.subcategoryBanner,
.subcategoryBanner img {
   /* background-color: #ffeb3b; */
   object-fit: cover;
   width: 100%;
   border-radius: 12px;
   height: 100%;
   object-position: top;
}

.subcategoryBanner span {
   width: 100%;
   height: 100%;
}

.imgText {
   position: absolute;
   top: 0;
   border-radius: 12px;
   font-weight: 400;
   color: #fff;
   text-align: left;
   padding: 30px;
   height: 500px;
   font-size: 26px;
   background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 10%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0) 100%);
}

.imgText span {
   font-weight: 500;
   font-size: 28px;
}

.roomCount {
   text-align: right;
   font-size: 18px;
   font-weight: 600;
   color: #aeaeae;
   height: 50px;
   align-items: flex-end;
}

.subcategoryDesc {
   overflow: hidden;
   height: 70px;
   text-align: left;
   font-weight: 500;
   color: #8e8e8e;
}

.subcategoryButton {
   color: #222255;
   cursor: pointer;
   font-weight: 500;
   font-size: 24px;
   display: flex;
   justify-content: flex-end;
   align-items: flex-end;
}

.d-full {
   width: 100%;
   display: flex;
   max-height: 180px;
   border-radius: 12px !important;
   border: 1px solid #c7cad9;

   .subcategoryForMobile {
      display: none;
   }

   /* box-shadow: 0px 0px 30px 1px rgba(100, 100, 100, 0.12); */
}

.marginTop {
   margin-top: 30px;
}

.noRoom {
   width: 100%;
   display: flex;
   height: 150px;
   justify-content: center;
   align-items: center;
   font-size: 30px;
   font-weight: 600;
   color: #8e8e8e
}

.noRoomDesc {
   width: 100%;
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: center;
   align-items: flex-start;
   font-size: 16px;
   font-weight: 600;
   color: #8e8e8e;

   .noShowCrateRoom {
      width: 100%;
      margin: 10px 0px;
      font-size: 16px;
      text-align: center;
   }
}

.CreateRoomBox {
   justify-content: space-between !important;
   padding: 20px;
   border-radius: 12px;
   border: 1px solid #aeaeae;
}

.CreateRoomBox .label {
   font-size: 24px;
   margin-bottom: 10px;
   display: block;
   font-weight: 600;
}

.createRoomButtonBox {
   display: flex;
   justify-content: right;
   align-items: flex-end;
   .contained-btn {
      font-size: 14px !important;
   }
}

.loadingOutsideDiv {
   height: 100%;
   display: flex;
   width: 100%;
   min-height: 150px;
   justify-content: center !important;
   align-items: center;
}

/*POST CSS*/

.postOutside {}

.postInside {
   border: 1px solid #c7cad9;
}

.postDescription {
   padding: 20px;

}

.postDescription .label {
   width: 100%;
   font-size: 28px;
   font-weight: 500;
   color: #222255;
   text-align: left;
}

.postDescription .tags {
   margin-top: 5px;
   width: 100%;
   color: #8e8e8e;
   font-size: 19px;
   font-weight: 500;
   text-align: left;
}

.postDescription .roomType {
   margin-top: 5px;
   width: 100%;
   color: #8e8e8e;
   font-size: 17px;
   font-weight: 500;
   text-align: left;
}

.postDescription .desc {
   margin-top: 10px;
   color: #8e8e8e;
   text-align: left;
   overflow: hidden;
   height: 70px;
   width: 100%;
   font-weight: 500;
}

.postDescription .seats {
   width: 100%;
   margin-top: 40px;
   color: #8e8e8e;
   text-align: left;
   overflow: hidden;
   font-weight: 500;
}

.postDescription .date {
   width: 100%;
   font-weight: 500;
   margin-top: 10px;
   color: #222255;
   text-align: left;
   overflow: hidden;
}

.postParticipacions {
   border: 1px solid #c7cad9;
   padding: 15px;
}

.leftMenuWrapper {
   padding: 0px 20px;

   .MuiSkeleton-text {
      transform: scale(1, 1);
   }
}

.postLabel {
   width: 100%;
   display: inline-block;
   font-size: 20px;
   color: #8e8e8e;
   text-align: left;
   font-weight: 600;
}

.postUserBox {
   margin-top: 5px;
   margin-bottom: 10px;
   width: 100%;
   display: flex;
   align-items: center;
}

.postUserBox.host img {
   margin-right: 15px;
   border-radius: 100%;
   width: 50px;
   height: 50px;
   object-fit: cover;
}

.postUserBox img {
   margin-right: 15px;
   border-radius: 100%;
   width: 40px;
   height: 40px;
   object-fit: cover;
}

.postUserRight {
   text-align: left;
   width: 100%;
}

.username {
   font-size: 18px;
   font-weight: 500;
   padding-right: 10px;
}

.userage {
   font-size: 17px;
   font-weight: 500;
   color: #8e8e8e;
}


.postPrice {
   height: 70px;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   padding-left: 20px;
   color: #222255;
   text-transform: uppercase;
   font-size: 24px;
   font-weight: 600;
}

.postPrice span {
   margin-left: 10px;
}

.postRegisterButton {
   height: 70px;
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 20px;
   color: #222255;
   text-transform: uppercase;
   font-size: 28px;
   font-weight: 600;
}

.subCategoryWrapper {
   padding: 20px;
   padding-top: 20px;
}

.subCategoryWrapper .MuiGrid-container {
   justify-content: flex-start;
}

.responsiveForLoggedIn {
   height: calc(100vh - 130px);
   overflow-y: auto;
}

.loadingDivOutside {
   height: 100%;
   width: 100%;
   align-items: center;
   justify-content: center !important;
}

.lazy-load-image-background.blur img {
   width: 100px;
   height: 100px;
}

.subcategoryBanner .lazy-load-image-background.blur img {
   width: 100%;
   height: 100%;
   border-radius: 12px;
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
   width: 100%;
}

.lazy-load-image-background.blur.lazy-load-image-loaded img {
   border-radius: 12px;
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.subcategoryBanner .lazy-load-image-background.blur.lazy-load-image-loaded img {
   border-radius: 12px;
   width: 100%;
   height: 100%;
   object-fit: contain;
}

.categoryWrapperClass {
   .room-details {
      .seats-time {
         height: auto;
      }
   }
}

@media only screen and (max-width: 980px) {
   .categoryWrapperClass {
      height: calc(100vh - 80px);
      overflow: auto;
      // margin-top: 0px !important;

      .loadingDivOutside {
         height: 100vh;
      }

      .scrollbarY {
         display: none !important;
      }

      .leftMenuWrapper {
         padding: 0px;

         .subcategoriesBackButton {
            margin-left: 20px;
         }

         .subcategoriesLabel {
            margin-left: 20px;
         }

         .subcategoriesList {
            border: none;
            height: auto;
            margin-bottom: 20px;

            .subcategoriesListBox {
               margin-bottom: 0px;
               border-bottom: 1px solid #c7cad9;
               border-radius: 0;

               &.active {
                  border-radius: 0;
               }
            }
         }
      }
   }

   .subCategoryWrapperNotloggedIn,
   .subCategoryWrapper {
      min-height: calc(100vh - 140px);
      padding: 0px;

      &.subCategory {
         // margin-top: 70px;
         flex-direction: column-reverse !important;
         flex-wrap: nowrap !important;
      }

      .subcategoryBannerBox {
         height: fit-content;
      }

      .subcategoryBreadcrumb {
         height: fit-content;
         padding: 5px 10px 10px 10px;

         .MuiBreadcrumbs-root {
            width: 100%;
            height: fit-content;
         }

         li {
            color: #222222;
         }

         p {
            font-size: 14px !important;
         }

         a {
            font-size: 14px !important;
            cursor: pointer;
            color: #222222;
         }
      }

      .loadingOutsideDiv {
         min-height: 400px;
      }

      .d-full {
         max-height: none;
         margin: 10px;

         .subcategoryPostOutisde {
            padding: 0px;
            height: auto;
            display: flex;
            flex-direction: column;

            .subcategoryPost {
               width: 100%;
               flex-basis: auto;

               .subcategoryName {
                  font-size: 20px;
                  height: fit-content;
                  padding: 10px 10px 5px 10px;
               }
            }


            .subcategoryButton {
               display: none;
            }

            .roomCount {
               display: none;
            }

            .subcategoryDesc {
               min-height: 50px;
               height: auto;
               padding: 0px 10px;
            }

            .subcategoryForMobile {
               margin-top: 10px;
               flex-basis: auto;
               border-top: 1px solid #aeaeae;
               display: flex;
               padding: 10px;

               .subcategoryButton {
                  display: block;
                  font-weight: 600;
                  font-size: 20px;
                  text-align: right;
               }

               .roomCountForSmall {
                  color: #c7cad9;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
               }

               .roomCount {
                  display: none;
               }

            }
         }

      }

      .noRoom {
         min-height: 400px;
         font-size: 18px;
      }

      .subcategoryBanner {
         border-radius: 0px;

         .MuiSkeleton-text {
            transform: scale(1, 1);
         }

         img {
            border-radius: 0px;

         }
      }
   }

   .responsiveBoxForCategories {
      height: calc(100vh - 150px);
      overflow-y: auto;
   }
}

@media only screen and (max-width: 1920px) and (min-width: 1360px) {
   .subCategoryWrapperNotloggedIn {
      /* margin-left: 10% !important;
      margin-right: 10% !important; */
      padding: 20px;
      padding-top: 20px;
   }

   /* .subcategoriesList {
      height: calc(100vh - 20px) !important;
   } */

   .responsiveBoxForCategories {
      margin-left: 11.5% !important;
      height: calc(100vh - 200px);
      margin-right: 11.5% !important;
      overflow-y: auto;
   }
}