@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500&display=swap");

/* * {
  padding: 0;
  margin: 0;
  font-family: "Baloo Bhai 2", cursive;
  overflow-x: hidden;
} */
.moderator-wrapperr {
  /* background-image: url(/static/media/welcome_background_image.275f2cb9.png) , url(/static/media/welcome_background_image.275f2cb9.png), url(/static/media/welcome_background_image.275f2cb9.png);
  background-repeat: no-repeat;
  background-position: 0px -28px, 170px 1599px, 1350px 768px;
  background-size: 29% 37% , 26% 19%, 27% 31% !important */
}
.moderator-wrapperr .categories-aside {
  border: 1px solid #E5E5E5;
  border-radius: 15px;
  background: #fff
}

.moderator-wrapperr .sidebar{
  box-sizing: border-box !important;
  max-height: calc(100vh - 64px) !important;
  min-height: calc(100vh - 64px) !important;
  overflow-x: auto !important;
  padding: 0px !important;
  margin-top: 12px;
  position: sticky;
  top: 66px; 
}

.background-image1-y-inner{
  width: 470px;
  position: absolute;
  top : -80px;
  left : 80px;
  z-index: 0;
}
.background-image2-y-inner{
  width: 470px;
  position: absolute;
  right: 0px;
  top:550px;
}
.background-image3-y-inner{
  width: 470px;
  position: absolute;
  left: 100px;
  top:1350px
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar::-webkit-scrollbar-track {
  background-color: lightgrey !important;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background:  #0000008f;
  border-radius: 5px;
}

.moderator-wrapperr .categories-head {
  padding: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #c7cad9;
  font-size: 18px;
}

.moderator-wrapperr div.buttons-div-moderator{
  padding-bottom: 15px !important;
}

.moderator-wrapperr .buttons-div-moderator.bBottom .MuiButton-contained{
  height: 30px !important;
}

.moderator-wrapperr .categories-head * {
  margin: 0px;
  
}

.moderator-wrapperr .daf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p15 {
  padding: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

/* .mb20 {
  overflow:hidden
}
.mb30 {
  margin-bottom: 30px;
} */

/* .moderator-wrapperr .bBottom {
  border-bottom: 1px solid rgb(192, 192, 192);
} */

.moderator-wrapperr .data {
  padding: 15px;
  /* border-bottom: 1px solid rgb(192, 192, 192); */
}
.moderator-wrapperr .daf.bBottom:last-child{
  border-bottom: none !important;
}

.moderator-wrapperr .daf.bBottom:first-child{
  border-top: 1px solid #c7cad9;
}

.selected-category{
  background : #fffae6
}

 .flex-sb-center{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

 .flex-sb{
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}
/* .bBottomYellow {
  border-bottom: 2px solid #ffcb00;
} */

.pointer {
  cursor: pointer;
}


.moderator-wrapperr .buttons-div-moderator{
  display: flex;
  justify-content: space-between;
  padding: 5px 10px !important;
}

.moderator-wrapperr .btn-Category-inner {
  box-shadow: none !important;
  background: #ffcd2f !important;

}

.moderator-wrapperr .btn-unselected {
  box-shadow: none !important;
  background: none !important;

}

.moderator-wrapperr .btn-age {
  width: 100%;
  justify-content: start !important;
  padding-left: 20px;
  /* border-bottom: 1px solid #E5E5E5; */
}


.moderator-wrapperr .btn-age.MuiButton-root {
  border-radius: 0px !important;
}

.moderator-wrapperr .btn-age-outter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
    margin-bottom: 1px;
    border-top: 1px solid #E5E5E5;
}
.moderator-wrapperr .btn-age{
  padding: 10px 15px !important;
}

.moderator-wrapperr .heading-moderator{
  font-size: 25px;
    font-weight: 600;
    margin-top: 0px;
    margin-left: 15px;
}

.moderator-wrapperr .list-item-outter{
  border : 1px solid #E5E5E5;
  border-radius: 10px;
  margin-bottom: 20px;
  background: #fff;
}
.moderator-wrapperr .list-first-div{
  margin-top:5px
}

.moderator-wrapperr .list-first-div-inside{
  color: #444461;
  font-weight: 600;
  padding-right: 10px;
  font-size: 16px;
}

.moderator-wrapperr .list-first-div .span-after-username{
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #00000050 !important;
}

.moderator-wrapperr .list-first-div > span {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #010138;
}

.moderator-wrapperr .list-second-div{
  color: #00000050;
}

.moderator-wrapperr .rooms {
  padding: 0px 5px;
}

.moderator-wrapperr .rooms svg{
  font-size: 11px;
  /* padding: 0px 7px 0px 16px; */
  position: relative;
  margin: 0px 5px;
  top: 1px;
  color: #00000030;
}

.moderator-wrapperr .list-item-content p {
  color: #c1c1c1;
  font-weight: 500;
  width: 80%;
  padding-top: 10px;
}

.moderator-wrapperr .list-item-outter button{
  background: #010138; 
  color: #fff;
  border: 1px solid #000;
  border-radius: 30px !important;
  padding: 5px 20px;
}

.moderator-wrapperr .buttons-div-moderator.null{
  background: unset;
  box-shadow: none;
  border-radius:unset ;
}

@media only screen and (max-width: 980px) {

  .moderator-wrapperr {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .moderator-wrapperr .list-first-div-inside{
    display: inline-block;
    width: 100%;
  }
  .moderator-wrapperr .list-first-div-inside span{
    display: inline-block;
    width: 70%;
  }

  .moderator-wrapperr .MuiListItemSecondaryAction-root {
    top: 20px !important;
  }
  .moderator-wrapperr .list-item-outter button{
    padding:5px 16px !important;
    font-size: 14px !important;
  }
  .list-thirth-text {
    word-break: break-word;
  }
  
  .moderator-wrapperr .background-image-y-outter {
    display: none;
  }

  .moderator-wrapperr .list-item-outter {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #E5E5E5;
  }

  .moderator-wrapperr .outer-moderator-list {
    padding: 0px !important;
    padding-bottom: 40px !important;
  }

  .moderator-wrapperr .list-item-content p {
    padding-top: 5px !important;
    padding-bottom: 10px !important;
    width: 100% !important;
  }
}