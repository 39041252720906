@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500&display=swap");

.colorYellow {
  color: #ffcb00;
}

.colorGrey {
  color: #7f8e9d;
}

.colorGreyNew {
  color: #979797;
}

.colorBlack {
  color: #27272a;
}

.colorBlue {
  color: #010039;
}

.fs10 {
  font-size: 10px;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs17 {
  font-size: 17px;
}

.fs18 {
  font-size: 18px;
}

.fs19 {
  font-size: 19px;
}

.fs20 {
  font-size: 20px;
}

.fs21 {
  font-size: 21px;
}

.fs22 {
  font-size: 22px;
}

.fs24 {
  font-size: 24px;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fBold {
  font-weight: bold;
}

.colorWhite {
  color: #fff;
}

.colorYellow {
  color: #ffcb00;
}

.colorGrey {
  color: #7f8e9d;
}

.colorBlack {
  color: #27272a;
}

.colorLightGrey {
  color: #7f8e9d8a;
}

.bgGrey {
  background-color: #e1dfdf45;
}

.bgWhite {
  background-color: #fff;
}

.bgRed {
  background-color: red;
}

.bgYellow {
  background-color: #ffcb00;
}

.bgDarkGrey {
  background: #ececec91;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs17 {
  font-size: 17px;
}

.fs18 {
  font-size: 18px;
}

.fs19 {
  font-size: 19px;
}

.fs20 {
  font-size: 20px;
}

.fs21 {
  font-size: 21px;
}

.fs22 {
  font-size: 22px;
}

.fs24 {
  font-size: 24px;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fBold {
  font-weight: bold;
}

.shadow {
  box-shadow: 0 0 6px #dddee1;
}

.accord {
  height: 35vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.categoriesBox {
  overflow: auto;
  scrollbar-width: none;
}

@media only screen and (min-width: 1280px) {
  .categories-container {
    padding-right: 8px;
  }
}

@media only screen and (max-width: 1024px) {
  .todayImage {
    max-width: 375px;
  }
}

.categories-aside {
  border: 1px solid #c7cad985;
  border-bottom-right-radius: 64px;
  max-height: 578px;
  overflow: auto;
  scrollbar-width: none;
}

.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  border: none;
  border-bottom-right-radius: 70px;
  width: 95%;
}

.categories-head {
  padding: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #c7cad9;
}

.daf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.text-uppercase {
  text-transform: uppercase;
}

.p15 {
  padding: 15px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pt15 {
  padding-top: 15px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.mb0 {
  margin-bottom: 0px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb50 {
  margin-bottom: 50px;
}

.mt0 {
  margin-top: 0px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.bBottom {
  border-bottom: 1px solid #E0E0E0;
}

.bor-bottom-myrooms {
  position: relative;
  border-bottom: 2px solid rgb(225 225 225 / 65%);
  z-index: -1;
  top: -2px;
}

.btn-groups {
  /* padding: 0 15px; */
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  align-items: center;
}

.btn-groups span {
  padding: 5px 10px;
  border-radius: 8px;
}

.rooms-accordion {
  padding: 0 15px;
}

.room-times {
  padding-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #c7cad985;
  border-bottom-right-radius: 64px;
}

.room-times h4 {
  padding: 0 15px 15px 15px;
}

.data {
  padding: 15px;
  /* border-bottom: 1px solid #C7CAD9; */
}

.bBottomYellow {
  border-bottom: 2px solid #ffcb00;
}

.pointer {
  cursor: pointer;
}

.sync-calendar img {
  height: 25px;
  width: 25px;
}

.d-flex {
  display: flex;
}

.dFlexAlignCenter {
  display: flex;
  align-items: center;
}

.room-details {
  /* padding: 12px; */
  border-radius: 12px;
  border: 1px solid #E0E0E0;
}

.blueCard {
  background-color: #f1f2f8;
}

/* .room-details:hover {
  background: #ececec91;
} */

.w18 {
  height: 18px;
  width: 18px;
}

.w20 {
  height: 20px;
  width: 20px;
}

.w22 {
  height: 22px;
  width: 22px;
}

.w24 {
  height: 24px;
  width: 24px;
}

.w32 {
  height: 32px;
  width: 32px;
}

.w40 {
  height: 40px;
  width: 40px;
}

.w48 {
  height: 48px;
  width: 48px;
}

.borderRight {
  border-right: 1px solid #c7cad9;
}

.pr10 {
  padding-right: 10px;
}

.m15 {
  margin: 15px;
}

.mr10 {
  margin-right: 10px;
}

.ml15 {
  margin-left: 15px;
}

.radius100 {
  border-radius: 100%;
}

.see-more {
  text-align: end;
  text-decoration: underline;
  padding-bottom: 30px;
  border-bottom: 1px solid #c7cad9;
  margin-bottom: 20px;
}

.feedback p {
  width: 99%;
  outline: none;
  border: 1px solid rgb(219 203 203 / 60%);
  padding: 10px 5px;
  border-radius: 4px;
  background-color: #fff;
  word-break: break-all;
}

.see-feedback {
  text-decoration: underline;
}

.border {
  border: 1px solid #c7cad9;
}

.room-types {
  border: 1px solid #c7cad9;
  border-radius: 20px;
  padding: 15px 0;
  margin-bottom: 30px;
}

.ptb15 {
  padding: 15px 0;
}

.plr15 {
  padding: 0 15px;
}

.room-head {
  padding: 0 15px 10px 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #c7cad9;
}

.room-head:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.radius4 {
  border-radius: 4px;
}

.radius8 {
  border-radius: 8px;
}

.radius15 {
  border-radius: 15px;
}

.w-100 {
  width: 100%;
}

.animate {
  animation: fadeInAnimation ease-out 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Header CSS  */

.appbar-credit {
  font-size: 14px;
  margin-left: 4px;
}

.contained-btn {
  background: #ffcb00 !important;
}

.cover {
  object-fit: cover;
}

/* Input checkbox CSS  */

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: #fff;
  height: 18px;
  width: 18px;
  border: 2px solid #0000008f;
  border-radius: 7px;
}

input[type="checkbox"]:checked {
  background: #ffcb00;
  border-color: #ffcb00;
}

input[type="checkbox"]:after {
  content: "";
  position: relative;
  left: 39%;
  top: 20%;
  width: 15%;
  height: 40%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
}

/* Input checkbox CSS  */

/* Modal Css  */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
  /* min-height: 10%;
  max-width: 24%; */
  height: auto;
  max-width: 800px;
}

/* Modal Css  */

.text-center {
  text-align: center;
  cursor: pointer;
}

.btn-transparent {
  padding: 7px 60px;
  font-size: 16px;
  background: #fff;
  outline: none;
  border: 1px solid #c7cad9;
  border-radius: 4px;
}

.btn-blue {
  padding: 7px 60px;
  font-size: 16px;
  background: #282842;
  outline: none;
  border: 1px solid #282842;
  border-radius: 4px;
  color: #fff;
}

.textDecorationNone {
  text-decoration: none;
}

/* Switch button Css  */
.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 11px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked+.slider {
  background-color: #c9cdd0;
}

.slider.round {
  border-radius: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0;
  background-color: #c9cdd0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider::before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: -15px;
  bottom: -3px;
  background-color: #010138;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.round::before {
  border-radius: 50%;
}

.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 0px;
  bottom: -3px;
  background-color: #698091;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider {
  cursor: pointer;
}

/* Switch button Css  */

/* Accordion CSS  */

.accord.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.accord.MuiCollapse-root .accord.MuiCollapse-entered {
  display: none;
}

.accord.MuiCollapse-root .accord.MuiCollapse-entered {
  display: none;
}

/* .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
} */

.MuiFormHelperText-contained {
  text-align: right !important;
}

.accord .MuiAccordion-root .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
}

/* .MuiSvgIcon-root {
  fill: #1e3354 !important;
  width: 16px !important;
  height: 16px !important;
} */
.accord .MuiAccordion-root .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
  fill: #1e3354 !important;
  width: 16px !important;
  height: 16px !important;
}

.MuiAccordion-root:before {
  display: none !important;
}

.accord .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

#demo {
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: none !important;
}

.categories-aside .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  border: none !important
}

.accord #panel1a-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.MuiAccordionDetails-root {
  padding: 0 16px !important;
}

.accord .MuiAccordionSummary-root.Mui-expanded {
  min-height: 0 !important;
  background: #fffae6;
}

.accord .MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

/* Accordion CSS  */
.ptblr {
  padding: 0 15px 10px 15px;
}

.room-times .bBottom:last-child {
  border-bottom: none;
}

/* Scrollbar CSS  */
.scrollbarY {
  height: 90vh;
  overflow-y: auto;
}

.scrollbarYNew {
  height: calc(100vh - 82px);
  overflow-y: auto;
  scrollbar-width: none;
}

.scrollbarYLeftSide {
  height: calc(100vh - 82px);
  overflow-y: auto;
  scrollbar-width: thin;
}

/* Scrollbar CSS  */

.pt0 {
  padding-top: 0 !important;
}

.action-status {
  padding: 15px 60px 15px 15px;
}

.seats-time {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.filters-chip {
  flex-wrap: wrap;
}

.width45 {
  width: 45%;
}

.hour-glass {
  height: 400px;
  width: 400px;
}

.mh75 {
  min-height: 75px;
}

.mhDetails {
  min-height: 40px;
}

.textUnderline {
  text-decoration: underline;
}

/* Room Tab CSS  */
.roomTab.active {
  border-bottom: 2px solid #ffcb00;
}

/* Loader CSS  */
.loader {
  position: absolute;
  top: 40%;
  right: 50%;
}

.upcomingScrollbar {
  height: calc(100vh - 82px);
  overflow-y: auto;
  -webkit-scrollbar-width: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.upcomingScrollbar::-webkit-scrollbar {
  display: none;
}

.hide_scroll::-webkit-scrollbar {
  display: none;
}

.daf .MuiTypography-body1 {
  font-weight: 500;
}

.pr {
  position: relative;
}

.seeMoreRoomButton {
  width: fit-content;
  margin-right: 0px;
  margin-left: auto;
  text-align: right;
  color: #010138;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}

.seeMoreRoomButton:hover {
  text-decoration: underline;
  color: #27272a;
}

.cancelledBtn {
  display: block;
  text-align: end;
  padding: 10px 40px;
  text-transform: uppercase;
}

.draggable-box {
  transition: transform 0.3s ease-out;
}

.DialogWithoutMargin .MuiDialog-paper {
  margin: 0 0.4rem;
}

.DialogWithoZeroMargin .MuiDialog-paper {
  margin: 0;
}

.NoRoomClassBox {
  margin-top: 50px;
  margin-bottom: 50px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.NoRoomClassBox span {
  font-weight: 500;
  font-size: 20px;
  margin-top: 5px;
  color: #0f0f0f;
}

.NoRoomClassBox img {
  width: 42px;
  height: 42px;
}

.submit-btn-modal {
  padding: 0.8rem 1.2rem;
  border-radius: 2rem;
  background: #ffcc00;
  color: #010039;
  font-weight: 600;
  text-transform: uppercase;

  transition: all 0.3s ease-in-out;
}

.submit-btn-modal:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
}

.submit-btn-modal:active {
  transform: scale(0.95);
}

/* media-query for mobile */
@media screen and (max-width: 768px) {
  .scrollbarYNew {
    height: auto;
  }
  
  .seats-time {
    height: auto;
    justify-content: space-between;
  }

  .w48 {
    height: 40px;
    width: 40px;
  }

  .upcoming-room_check-mark {
    bottom: 10px;
    height: 15px;
  }

  .action-status {
    padding-right: 10px;
  }

  .accord {
    height: 70vh;
  }

  .accord::-webkit-scrollbar {
    display: none;
  }

  .btn-groups {
    justify-content: space-between;
    padding: 0 1rem;
  }

  .categories-aside {
    border: none;
  }

  .filters-chip {
    padding: 0 0.5rem;
    width: 100dvw;
    overflow: auto;
    flex-wrap: nowrap;
  }

  .filters-chip::-webkit-scrollbar {
    display: none;
  }

  .room-times-container {
    max-height: 43vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .room-times-container::-webkit-scrollbar {
    display: none;
  }

  .room-times {
    border: none;
    margin-bottom: 0;
    padding-top: 0;
  }

  .room-times>h4 {
    padding-top: 15px;
  }

  .room-types {
    margin-bottom: 0;
    padding-top: 0;
  }

  .room-head:first-child {
    padding-top: 15px;
  }

  .seeMoreRoomButton {
    margin-bottom: 1rem;
  }

  .register-room {
    z-index: 1300;
    width: 100dvw;
    top: 7rem;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
  }

  .upcomingScrollbar {
    height: 50dvh;
  }

  .fullHeight .MuiDialog-container {
    height: auto;
    margin-top: 2rem;
  }

  .upcomingRoomsSkeleton:first-child {
    margin-top: 1.5rem;
  }
}

/* media query for extreme smaller screens */
@media screen and (max-height: 680px) {
  .upcomingScrollbar {
    height: 66dvh;
  }
}

/* homepage slides */
.truncate1Line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.textTour {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.newOverlay::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.room-type-border {
  color: #010039;
  border: 1px solid #E0E0E0;
  border-radius: 50px;
  padding: 4px 8px;
  font-size: 10px;
}
.roomButton {
  color: white;
  background-color: #121173;
  border-radius: 100px;
  padding: 5px 10px;
}