.accountDetailsOutside {
   width: 100%;
   position: absolute;
   height: calc(100vh - 66px);
   top:66px;
   display: flex;
   justify-content: flex-end;
   right: 0px;
   z-index: 99;
   backdrop-filter: blur(5px);
   background-color: rgba(0, 0, 0, 0.2);
}

.accountDetailsFrom {
   overflow-y: scroll;
   background-color: #fff;
   padding: 20px;
   position: relative;
   width: 40%;
   height: 100%
}

.accountDetailsFrom input {
   background-color: #fff;
   box-shadow: none;
   padding: 12px;
   border: 1px solid #e5e5e5;
   border-radius: 8px;
   width: 100%;
   font-size: 14px;
   transition: .5s;
   font-weight: 500;
}

.accountDetailsFrom label {
   font-size: 16px;
   color: #222255;
   font-weight: 500;
   margin-bottom: 5px;
   margin-top: 10px;
   display: block;
   width: 100%;
}

.walletBackButton {
   cursor: pointer;
   font-size: 22px;
   display: flex;
   font-weight: 500;
   padding-bottom: 15px;
   align-items: center;
   padding-left: 0px;
   text-align: left;
}

.walletBackButton img {
   margin-right: 13px;
   width: 15px;
   height: 15px;
}

.detailButton {
   width: 100%;
   margin-top: 30px;
   margin-bottom: 0px;
   cursor: pointer;
   padding: 16px;
   background-color: #232344;
   color: #fff;
   font-size: 18px;
   border-radius: 12px;
   text-align: center;
   border: none;
   font-weight: 600;
}

.detailButtonDisabled {
   width: 100%;
   margin-top: 30px;
   cursor: not-allowed;
   padding: 16px;
   background-color: #e5e5e5;
   color: #fff;
   font-size: 18px;
   border: none;
   box-shadow: none;
   border-radius: 12px;
   text-align: center;
   font-weight: 600;
}

.detailsErrorBox {
   font-size: 13px;
   margin-top: 10px;
   color: red;
   height: 20px;
   text-align: left;
   font-weight: 500;
}

.accountDetailsFrom .error {
   background-color: #fff;
   margin-left: 5px;
   box-shadow: none;
   padding: 15px;
   border: 1px solid red;
   border-radius: 8px;
   width: 100%;
   font-size: 15px;
   transition: .5s;
   font-weight: 500;
}

.loadingMask {
   right: 0px;
   position: absolute;
   width: 40%;
   z-index: 99;
   backdrop-filter: blur(4px);
   height: 100%;
   background-color: transparent;
}

@media only screen and (max-width: 900px) {
   .loadingMask {
      width: 100%;
   }
}