/*ACCOUNT STATEMENT */

.enterBankDetailsButton {
   cursor: not-allowed;
   padding: 10px;
   font-size: 16px;
   font-weight: 500;
   width: 100%;
   text-align: center;
   border: 2px solid #eaeaea;
   background-color: #eaeaea;
   border-radius: 8px;
   color: #8e8e8e;
   margin-top: 50px;
   margin-bottom: 50px;
}

.enterBankDetailsButton.active {
   cursor: pointer;
   padding: 10px;
   font-size: 16px;
   font-weight: 500;
   width: 100%;
   text-align: center;
   border: 2px solid #222255;
   background-color: #eaeaea;
   border-radius: 8px;
   color: #222255;
   margin-top: 50px;
   margin-bottom: 50px;
}

.accountStatementBox {
   width: 100%;
   display: flex;
   flex-direction: column;
}

.accountStatementLabels {
   justify-content: space-between;
   display: flex;
   color: #8e8e8e;
   font-size: 18px;
   font-weight: 600;
}

/* RATING */

.ratingBox {
   margin-top: 30px;
   display: flex;
   flex-direction: column;
}

.earnedCoinsCurrentRating {
   display: flex;
   justify-content: space-between;
   font-size: 16px;
   font-weight: 600;
}

.earnedAndTime {
   display: flex;
   flex-direction: column;
}

.moderatorExplainText {
   font-size: 14px;
   width: 100%;
   font-weight: 500;
}

.moderatorExplainText ul {
   padding: 0px 20px;
}

.moderatorExplainText span {
   color: #222255;
}

.timeAndHearth {
   margin-top: 3px;
   display: flex;
   justify-content: space-between;
   font-size: 14px;
   font-weight: 500;
   color: rgba(1, 0, 57, 0.4);
}

.ratingDesc {
   padding:12px;
   border-radius: 8px;
   border: 1px solid rgba(1, 0, 57, 0.4);
   margin-top: 30px;
   color: rgba(1, 0, 57, 0.4);
   font-weight: 600;
   font-size: 14px;
   display: flex;
}

.ratingDesc img {
   margin-top: 5px;
   margin-right: 12px;
   width: 20px;
   height: 20px;
}

.accounStatementDataBox {
   border-bottom: 1px solid #e5e5e5;
   margin-top: 20px;
   padding-bottom: 20px;
   display: flex;
   flex-direction: column;
   font-weight: 600;
}

.accountDataTypeAndPrice {
   display: flex;
   justify-content: space-between;
   color: #222255;
   font-size: 18px;
   font-weight: 600;

}

.accountDataTıme {
   margin-top: 5px;
   color: #8e8e8e;
   font-size: 16px;
}

.accountParticipants {
   margin-top: 5px;
   font-size: 16px;
   color: #8e8e8e;
}

.accountCompleted {
   margin-top: 5px;
   font-size: 16px;
   gap: 10px;
   display: flex;
   color: #8e8e8e;
}

.heartCount {
   display: flex;
   align-items: center;
}

.hearthCountBad {
   display: flex;
   align-items: center;
}

.heartCount img {
   margin-right: 4px;
   width: 20px;
   height: 20px;
}

.hearthCountBad img{
   margin-right: 4px;
   width: 20px;
   height: 20px;
   filter: grayscale(100%)
}

.bankDetailsOutsideBox {
   padding-left: 20px;
   width: 50%;
}

.bankDetailsBox {
   background-color:#fff ;
   margin-top: 24px;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   border-radius: 12px;
   border: 1px solid #E0E0E0;
   font-size: 14px;
   font-weight: 500;
   position: relative;
}

.bankDetailEditButton {
   cursor: pointer;
   position: absolute;
   right: 3px;
   top: 15px;
   width: 25px;
   height: 25px;
}

.bankDetailEditButton img {
   width: 15px;
   height: 15px;
}

.bankDetailBoxUserName {
   padding: 12px;
   border-bottom: 1px solid #E0E0E0;
   color: #222255;
}

.bankDetailInfo {
   padding: 4px 12px;
   color: #8e8e8e;
}