html,
body,
#root,
#root>div {
  overflow-y: auto;
}

.custom-button {
  background-color: #FFCB00;
  padding: 5px;
  text-align: center;
  border-radius: 20px;
  font-weight: 600px
}

.custom-button:hover {
  background-color: rgb(178, 142, 0);

}

.custom-button:active {
  background-color: rgb(178, 142, 0);
}

.menu-list>ul {
  margin-bottom: 0px !important;
}

.nav-menus {
  padding-bottom: 10px;
}

.nav-menus:hover {
  cursor: pointer;
  border-bottom: 1px solid #ffcb00;
}

.enter-btn {
  background: #ffcb00 !important;
}

.desc-syl {
  color: #b5b3be;
  font-size: 20px;
}

#para-syl {
  color: #000;
}

.store-btn {
  width: 10px;
  height: 40px;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  width: 350px;
  background-color: #ffcb00;
  color: #010039;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -130px;
  left: -2px;
  font-size: 18px;
  padding: 16px 8px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffcb00 transparent transparent transparent;
}

.dotted-line-vertical {
  position: relative;
  width: 20px;
}

.dotted-line-vertical::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: linear-gradient(black 63%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 1px 38px;
  background-repeat: repeat-y;
  z-index: -1;
}

.dotted-line-vertical::after {
  content: '';
  position: absolute;
  inset: 0;
  background-image: linear-gradient(black 63%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 38px;
  background-repeat: repeat-y;
  z-index: -1;
}

/* articles */

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
  -webkit-box-orient: vertical;
}

.truncate1Line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
  -webkit-box-orient: vertical;
}
/* articles */
/* For larger than medium (lg, xl) screens */
@media (min-width: 1280px) {
  .branchBox {
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
  }

  .thousandsOfPeople {
    font-size: 36px;
  }
}



/* For medium (md) screens */
@media (min-width: 960px) {
  .branchBox {
    display: flex;
  }

  .thousandsOfPeople {
    font-size: 24px;
  }

  .headerTitle {
    font-size: 58px;
  }

}

@media (max-width: 1100px) {
  
  .headerTitle {
    font-size: 48px;
  }
  
}

@media (max-width: 600px) {
  .slick-dots {
    height: 20px;
    overflow: auto;
  }
  .thousandsOfPeople {
    font-size: 24px;
  }

  .headerTitle {
    font-size: 40px;
  }
  .imageScale {
    transform: scale(0.9);
  }
}

@media (max-width: 380px) {
  .headerTitle {
    font-size: 32px;
  }
  .MuiButton-containedSizeLarge {
    font-size: 12px !important;
  }
  .imageScale {
    transform: scale(0.75);
  }
}

.small-text {
  font-size: 12px;
  color: #707070;
  padding-left: 5px;
}

/* react-slick */
.slick-slide > div {
  margin: 0 auto; /* Centers the content inside the slide */
  width: 90%; /* Adjust width to fit inside the margin */
}
.slick-dots li button:before {
  font-size: 15px;
  line-height: 20px;
}

.slick-dots li.slick-active button:before {
  color: #FFCB00 !important;
}

/* for slideshow part */
:root {
  --border-radius: 10px;
  /* Variable for border radius */

  --medium-w-box: 180px;
  --medium-h-box: 140px;
  --medium-h-img: 140px;

  --small-w-box: 120px;
  --small-h-box: 90px;
  --small-h-img: 90px;

  /* size on phone */

  --xs-medium-w-box: 130px;
  --xs-medium-h-box: 96px;

  --xs-small-w-box: 85px;
  --xs-small-h-box: 65px;
}

@keyframes swipeLeft {
  from {
    transform: translateX(20%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes swipeRight {
  from {
    transform: translateX(-20%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes swipeDown {
  from {
    transform: translateY(-20%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes swipeUp {
  from {
    transform: translateY(20%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.item.swipeLeft {
  animation: swipeLeft 1s ease-out forwards;
}

.item.swipeRight {
  animation: swipeRight 1s ease-out forwards;
}

.item.swipeDown {
  animation: swipeDown 1s ease-out forwards;
}

.item.swipeUp {
  animation: swipeUp 1s ease-out forwards;
}

.slideContainer {
  background-image: url('../../assets/homepage/png_chip_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;

  background-size: contain;
  align-items: center;
  position: relative;
  justify-content: center;
  padding: 20px 10px;
}

.item {
  border-radius: var(--border-radius);
  box-shadow: 10px 20px 20px 10px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.item:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

.bgimage {
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
}

.itemMedium {
  width: var(--medium-w-box);
  height: var(--medium-h-box);
}

.itemSmall {
  width: var(--small-w-box);
  height: var(--small-h-box);
}

.itemMediumImg {
  width: var(--medium-w-box);
  height: var(--medium-h-box);
}

.itemSmallImg {
  width: var(--small-w-box);
  height: var(--small-h-box);
}

.xsitemMediumImg {
  width: var(--xs-medium-w-box);
  height: var(--xs-medium-h-box);
}

.xsitemSmallImg {
  width: var(--xs-small-w-box);
  height: var(--xs-small-h-box);
}

@keyframes opancityAnimation {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.animationOpancity {
  animation-name: opancityAnimation;
  animation-duration: 15s;

  animation-iteration-count: infinite;
}

.mediumWidth {
  width: var(--medium-w-box);
}

.smallWidth {
  width: var(--small-w-box);
}

.footer {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.image {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  object-fit: cover
}

.image_wrapper {
  position: relative;
  border-radius: var(--border-radius);
}

.overlay {
  position: absolute;
  background: rgba(57, 57, 57, 0.5);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);

  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay_1 {
  left: 0;
  top: 0;
  padding: 5px;
}

.paused {
  animation-play-state: paused;
  transition: none !important;
  /* Overrides any existing transitions */
}


/* for single slide */
.m-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.singleSlide {
  transition: opacity 1s ease; /* Smooth transition for 1.5 seconds */
  opacity: 1; /* Default opaque state */
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.fade-out {
  opacity: 0; /* Fully transparent */
}

.fade-in {
  opacity: 1; /* Fully opaque */
}


/* effortless  */
@keyframes effortlessAnimationUp {
  0% {
    transform: translateY(50px) scale(0.7);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}

@keyframes effortlessAnimationDown {
  0% {
    transform: translateY(0px) scale(1);
  }
  100% {
    transform: translateY(50px) scale(0.7);
  }
}

.animationDown {
  animation-name: effortlessAnimationDown;
  animation-duration: 1s;
  animation-timing-function: ease-in-out; 
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.animationUp {
  animation-name: effortlessAnimationUp;
  animation-duration: 1s;
  animation-timing-function: ease-in-out; 
  animation-iteration-count: 1; 
  /* animation-fill-mode: forwards; */
}
