.main-feedback-container {
  padding: 15px;
}

.main-feedback-container .header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.main-feedback-container .header div {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  margin-right: 20px;
}

.main-feedback-container .header div h1 {
  font-size: 21px;
  padding: 3px 0px;
}

.main-feedback-container .header img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.main-feedback-container .sub-header {
  display: flex;
  justify-content: flex-start;
  width: 70%;
}

.main-feedback-container .sub-header span {
  color: #cdcdcd;
  font-weight: 500;
  font-size: 18px;
  padding-top: 14px;
}

.main-feedback-container .emoji-container {
  display: flex;
  justify-content: space-around;
  padding: 30px 15px;
}

.rooom-feedback-wrapper {
  margin-top: 15px;
}

.rooom-feedback-wrapper .MuiInputBase-root {
  font-size: 14px !important;
}

.rooom-feedback-wrapper p {
  color: #101038;
  font-weight: 500;
  font-size: 1em;
  margin-bottom: 5px;
}

.drop-down .dropdown-content {
  font-size: 1rem !important;
}

#emoji {
  display: flex;
}

#emoji img {
  width: 40px;
  height: 40px;
  margin: 0 2rem;
  cursor: pointer;
}

#emoji img:hover {
  transform: scale(1.2);
  width: 40px;
  height: 40px;
  margin: 0 2rem;
}

#emoji .applyFilter {
  filter: grayscale(1);
}

.cancel-image-feedback {
  cursor: pointer;
}

.submit-btn-container {
  padding-top: 30px;
}

.submit-btn {
  width: 100%;
  background: #101038 !important;
  color: #fff !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.submit-btn:disabled {
  background-color: #dbdae3 !important;
  cursor: not-allowed !important;
}

.your-ratings-container {
  display: flex;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.your-ratings-container p {
  color: #101038;
  font-weight: 500;
}

.feedback-sorry-btn-outter {
  padding-top: 30px;
  display: flex;
  justify-content: space-evenly;
}

.feedback-sorry-btn-inner {
  background: #fff;
  color: #cdcdcd;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #cdcdcd;
  font-weight: 600;
}

.feedback-sorry-btn-inner2 {
  background: #101038;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.feedback-sorry-p-outter {
  margin-top: 20px;
  color: #cdcdcd;
  font-weight: 500;
  font-size: 15px;
}

.ask-refund-text {
  margin-bottom: 0px;
}

.feedback-sorry-span-inner {
  color: #cdcdcd;
  font-weight: 500;
  font-size: 15px;
  padding-top: 15px;
}

.feedback-sorry-span-outter {
  display: flex;
  justify-content: flex-start;
  width: 80%;
}

.feedback-sorry-cancel {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.feedback-sorry-outter {
  padding: 15px;
  width: 400px;
}

.feedback-sorry-inner {
  position: absolute;
  right: 1rem;
}

#error-msg {
  position: fixed;
  z-index: 9999999;
}

.feedback-refund-outter {
  width: 400px;
}

.feedback-refund-outter > div > img {
  width: 400px;
  height: 250px;
}

.refund-message-container {
  background-image: linear-gradient(#ffcb00 0%, #ffffff 100%);
  width: 400px;
  height: 400px;
  padding: 30px;
}

.feedback-refund-span-outter {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  padding: 20px;
  background: #fff;
  flex-direction: column;
  text-align: center;
  height: 250px;
}

.feedback-refund-span-inner {
  color: #666666;
  font-weight: 500;
  font-size: 15px;
}

.feedback-refund-span-inner-bold {
  font-weight: 600;
  color: #000;
  margin-top: 0.5rem;
}

.feedback-refund-btn-outter {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.feedback-refund-btn-inner {
  width: 90%;
  background: #101038 !important;
  color: #fff !important;
  border-radius: 10px;
  padding: 10px !important;
}

.particiapnt-list > div:not(:last-child) {
  margin-bottom: 1.5rem;
}

.report-list-item {
  cursor: pointer;
  transition: all 0.5s ease;
}

.report-list-item:hover {
  background: #e5e5e5;
}

.mobile-snackbar-styles {
  min-width: 250px;
  margin: auto;
  text-align: center;
  border-radius: 3.2rem;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: black;
  position: fixed;
  top: 8%;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;
  padding: 12px 16px;
  font-size: 15px;
}
