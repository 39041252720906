@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span,
b,
ul,
li,
ol {
  font-family: "Twemoji Country Flags", 'Poppins' !important;
}

#room {
  margin-top: 4.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #f5f5f5;
  height: 92%;
}

#room-error-msg {
  color: red;
  background-color: #f5f5f5;
}

#error-msg svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit {
  color: red !important;
}

@media screen and (max-width: 750px) {
  #room {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-top: 60px; /* header height */
    background: #f5f5f5;
  }
  
  #ice-breaker {
    width: 70vw;
    left: 0;
    top: 4rem;
    font-size: .8rem;

    padding: .5rem;
  }

  .feedback-sorry-btn-outter {
    justify-content: center;
    align-items: center;
  }

  .feedback-refund-outter {
    width: auto;
  }

  .refund-message-container {
    width: auto;
    height: auto;
  }
}
